import _ from 'lodash';

import { AccountsResponse } from '../../models';

export default function getLocalAccounts(accounts: AccountsResponse) {
  // Make a clone of the accounts.accounts object
  // Unfortunately with JS there is no easy way to deep clone objects so need to use lodash
  const accountsList = accounts.accounts === null ? [] : accounts.accounts;
  const localAccounts = _.cloneDeep(accountsList);
  // Sort the accounts by parents first
  localAccounts.sort((a, b) => (a.is_parent === b.is_parent ? 0 : a.is_parent ? -1 : 1));

  return localAccounts;
}
