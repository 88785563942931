import { styled } from '@mui/material/styles';

import { RootContainer } from '../RootContainer';

const StyledRootContainer = styled(RootContainer)(() => ({
  '& .MuiList-padding': {
    paddingBottom: 0,
  },
})) as typeof RootContainer;

export default StyledRootContainer;
