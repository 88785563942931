import { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, IconButton, Paper, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import useAPIClient from '../../hooks/useClient';
import { CreatePaymentRequest, GetMandateResponse } from '../../models';
import { isAuthorizationError } from '../../services';
import { getValue } from '../../services/storage';
import CreatePaymentErrorModal from './CreatePaymentErrorModal';
import CreatePaymentSuccessModal from './CreatePaymentSuccessModal';

type AppProps = {
  open: boolean;
  handleClose: () => void;
  mandateData?: GetMandateResponse;
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  containerDesktop: {
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  linkBase: {
    margin: 'auto',
    backgroundColor: 'white',
  },
  linkDesktop: {
    overflow: 'hidden',
    minHeight: '720px', // 16:9 ratio
    height: '100%',
    maxHeight: '720px',
    width: '405px',
    minWidth: '405px',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  linkMobile: {
    height: '100%',
    width: '100%',
  },
  logo: {
    height: '80px',
    width: '80px',
    margin: 'auto',
  },
  formGroup: {
    width: '88%',
    flexWrap: 'nowrap',
    '&:hover': {
      '& fieldset': {
        borderColor: 'black',
      },
    },
    margin: 'auto',
  },
  textInput: {
    marginBottom: '30px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    height: '46px',
    '& input': {
      height: '9px',
    },
    '& label': {
      color: '#ABABAB',
      top: '-5px',
    },
    autoCapitalize: 'none',
    autoCorrect: 'off',
  },
  error: {
    color: '#E20404',
    margin: 0,
  },
}));

type Inputs = {
  amount: string;
  description?: string;
  txnRef?: string;
};

export default function CreatePaymentModal({ open, handleClose, mandateData }: AppProps): JSX.Element {
  const [mandateId, setMandateId] = useState(mandateData?.mandate_id ?? '');
  const [currency, setCurrency] = useState(mandateData?.mandate_details.currency ?? '');
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errTitle, setErrTitle] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const [paymentId, setPaymentId] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  const client = useAPIClient();
  const history = useHistory();
  const isMobile = useCallback((): boolean => {
    return (
      window.matchMedia('(max-width: 760px)').matches &&
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  }, []);
  const isPortrait = window.innerWidth > window.innerHeight ? false : true;
  const classes = useStyles();

  const containerStyle = `${classes.container} ${isMobile() && isPortrait ? '' : classes.containerDesktop}`;
  const linkStyle = `${classes.linkBase} ${isMobile() && isPortrait ? classes.linkMobile : classes.linkDesktop}`;

  const { register, errors, handleSubmit } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    try {
      const modifiedAmount = Math.round(parseFloat(data.amount) * 100);
      const payload: CreatePaymentRequest = {
        amount: modifiedAmount,
        currency: currency,
        payment_details: {
          description: data.description ?? '',
          mandate_id: mandateId,
          transaction_reference_id: data.txnRef ?? '',
        },
        type: 'MANDATE',
      };

      const paymentDetail = await client.createPayment(String(getValue('userToken')), payload);

      if (paymentDetail.status === 400) {
        // failed with error
        setErrTitle('Failed to create payment');
        setErrMessage(paymentDetail.data.message);
        setErrorModalVisible(true);
      } else {
        // success
        setPaymentId(paymentDetail.payment_id);
        setSuccessModalVisible(true);
      }
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        setErrTitle('Failed to create payment');
        setErrMessage(err.message);
        setErrorModalVisible(true);
      }
    }
  };

  const handleErrorClose = () => {
    setErrorModalVisible(false);
  };
  const handleSuccessClose = () => {
    setSuccessModalVisible(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box className={containerStyle}>
            <Box className={linkStyle}>
              <Box style={{ width: '100%', textAlign: 'right' }}>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Avatar className={classes.logo} src="/img/institutions/default.svg" />
              <h1 style={{ textAlign: 'center' }}>Payment Execution</h1>

              <Box style={{ width: '88%', margin: 'auto', paddingBottom: '25px' }}>
                <Paper>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ padding: '10px' }}>Mandate ID:</TableCell>
                        <TableCell style={{ padding: '10px' }}>{mandateId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: '10px' }}>Currency:</TableCell>
                        <TableCell style={{ padding: '10px' }}>{currency}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </Box>

              <form autoComplete="off" className={classes.formGroup} onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  id="amount"
                  name="amount"
                  label="Amount"
                  key="Amount"
                  placeholder="Amount"
                  helperText="e.g. 1000.12 (only up to 2 decimal places)"
                  variant="outlined"
                  className={classes.textInput}
                  error={errors['amount'] !== undefined}
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*.[0-9]{2}$',
                  }}
                  inputRef={register({
                    required: 'only up to 2 decimal places',
                  })}
                />
                <TextField
                  id="description"
                  name="description"
                  label="Description (Optional)"
                  key="description"
                  placeholder="Description (Optional)"
                  helperText="Maximum length: 20 characters"
                  variant="outlined"
                  className={classes.textInput}
                  inputRef={register()}
                />
                <TextField
                  id="txnRef"
                  name="txnRef"
                  label="Transaction Reference (Optional)"
                  key="txnRef"
                  placeholder="Unique Transaction Reference (Optional)"
                  helperText="Maximum length: 10 characters"
                  variant="outlined"
                  className={classes.textInput}
                  inputRef={register()}
                />
                <Button fullWidth={true} variant="contained" color="primary" type="submit">
                  Continue
                </Button>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <CreatePaymentErrorModal
        open={errorModalVisible}
        handleClose={handleErrorClose}
        handleCloseCreateModal={handleClose}
        errorTitle={errTitle}
        errorMessage={errMessage}
      />
      <CreatePaymentSuccessModal
        open={successModalVisible}
        handleClose={handleSuccessClose}
        handleCloseCreateModal={handleClose}
        paymentId={paymentId}
      />
    </>
  );
}
