import React, { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';

import { AccountBalanceWallet, MoreVert } from '@mui/icons-material';
import { List, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { commonStyle } from '../components/commonStyle';
import LinkLogo from '../components/LinkLogo';
import { RootContainer } from '../components/RootContainer';
import StatusIcon from '../components/StatusIcon';
import { StyledAvatar, StyledListItem, StyledListItemAvatar, StyledListItemText } from '../components/styledComponents';
import AccountList from '../components/User/AccountList';
import StyledMenu from '../components/User/StyledMenu';
import UnlinkDialog from '../components/User/UnlinkDialog';
import ViewProductListItem from '../components/User/ViewProductListItem';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { AccountsResponse, LoginIdentityResponse, APIErrorOrUndefined } from '../models/index';
import { getValue, isAuthorizationError } from '../services';
import { isAPIError } from '../services/typeGuards';
import { isEndStatus, isNotSupported, isProductEndStatus } from './Utilities';

const notSupportedMsg = {
  status: 200,
  code: 10000,
  message: 'This product is not supported by this institution',
  name: 'NOT_SUPPORTED',
};

export enum CurrentView {
  LOGIN_IDENTITY = 1,
  ACCOUNTS,
  TRANSACTIONS,
  CURR_TRANSACTIONS,
  LOGIN_IDENTITY_HISTORY,
  STATEMENTS,
  IDENTITY,
  INCOME,
}

export enum CurrentDialog {
  NONE = 0,
  UNLINK_CONFIRM,
  JSON,
}

interface Props {
  setTopLevelAccounts: Dispatch<SetStateAction<AccountsResponse | undefined>>;
  setTopLevelLoginIdentity: Dispatch<SetStateAction<LoginIdentityResponse | undefined>>;
}

export default function User({ setTopLevelAccounts, setTopLevelLoginIdentity }: Props): JSX.Element {
  const classes = commonStyle();
  const history = useHistory();
  const client = useAPIClient();

  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);
  const { data: loginIdentity, error: loginIdentityError, mutate: mutateLoginIdentity } = useLoginIdentityId(token);
  const loginIdentityStatus = loginIdentity?.login_identity?.status ?? '';
  const loginIdentityId = loginIdentity?.login_identity.login_identity_id ?? '';

  const {
    data: loginIdentityHistory,
    error: loginIdentityHistoryError,
    mutate: mutateLoginIdentityHistory,
  } = useSWR(
    isEndStatus(loginIdentityStatus) ? [`/login-identity-history/${loginIdentityId}`, token, loginIdentityId] : null,
    (_: string, token: string, liid: string) => client.getLoginIdentityHistory(token, liid),
  );
  const {
    data: accounts,
    error: accountsError,
    mutate: mutateAccounts,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.accounts?.status ?? '')
      ? ['/accounts', token]
      : null,
    (_: string, token: string) => client.getAccounts(token),
  );
  const {
    data: transactions,
    error: transactionsError,
    mutate: mutateTransactions,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.online_transactions?.status ?? '')
      ? ['/transactions', token]
      : null,
    (_: string, token: string) => client.getTransactions(token),
  );
  const {
    data: statementsListData,
    error: statementsError,
    mutate: mutateStatements,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.statements?.status ?? '')
      ? ['/statements', token]
      : null,
    (_: string, token: string) => client.getStatements(token),
  );
  const {
    data: identityData,
    error: identityError,
    mutate: mutateIdentity,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.identity?.status ?? '')
      ? ['/identity', token]
      : null,
    (_: string, token: string) => client.getIdentity(token),
  );

  const {
    data: income,
    error: incomeError,
    mutate: mutateIncome,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.income_estimation?.status ?? '')
      ? ['/income', token]
      : null,
    (_: string, token: string) => client.getIncomeEstimation(token),
  );

  const statementsList = useMemo(
    () =>
      isNotSupported(loginIdentity?.login_identity.product_status.statements?.status_details ?? '')
        ? notSupportedMsg
        : statementsListData,
    [statementsListData, loginIdentity],
  );
  const identity = useMemo(
    () =>
      isNotSupported(loginIdentity?.login_identity.product_status.identity?.status_details ?? '')
        ? notSupportedMsg
        : identityData,
    [identityData, loginIdentity],
  );

  const [view, setView] = useState<CurrentView>(CurrentView.LOGIN_IDENTITY);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleViewClick = (view: CurrentView) => {
    setView(view);
    setDialog(CurrentDialog.JSON);
    handleMenuClose();
  };

  const showUnlinkConfirm = () => {
    setDialog(CurrentDialog.UNLINK_CONFIRM);
  };

  const handleClose = () => {
    setDialog(CurrentDialog.NONE);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const mutate = async () => {
    handleMenuClose();
    mutateLoginIdentity();
    mutateLoginIdentityHistory();
    mutateAccounts();
    mutateTransactions();
    mutateStatements();
    mutateIdentity();
    mutateIncome();
  };

  const getListItemSecondaryText = (accounts: APIErrorOrUndefined<AccountsResponse>): string => {
    if (isAPIError(accounts)) return accounts.message;
    if (accounts === undefined || accounts.accounts === null) return 'Fetching accounts';
    return `${accounts?.accounts.filter((a) => !a.is_parent).length} accounts linked`;
  };

  const viewStatements = async () => {
    history.push('/statements');
  };

  const viewIdentity = async () => {
    history.push('/identity');
  };

  const viewIncomeEstimation = async () => {
    history.push('/income');
  };

  useEffect(() => {
    if (loginIdentity === undefined) {
      return;
    }
    setTopLevelLoginIdentity(loginIdentity);
  }, [loginIdentity, setTopLevelLoginIdentity]);

  useEffect(() => {
    if (accounts === undefined) {
      return;
    }
    setTopLevelAccounts(accounts);
  }, [accounts, setTopLevelAccounts]);

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errorList = [
      loginIdentityError,
      loginIdentityHistoryError,
      accountsError,
      transactionsError,
      statementsError,
      identityError,
      incomeError,
    ];
    if (errorList.some((error) => error !== undefined && isAuthorizationError(error))) {
      history.push('/login');
    }
  }, [
    loginIdentityError,
    loginIdentityHistoryError,
    accountsError,
    transactionsError,
    statementsError,
    identityError,
    incomeError,
    history,
  ]);

  return (
    <>
      <div className={classes.main}>
        <RootContainer>
          <LinkLogo title="Linked accounts" component={AccountBalanceWallet} />
          <List>
            <StyledListItem key={loginIdentity?.institution?.institution_id} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={loginIdentity?.institution?.institution_id}
                  src={`/img/square/${loginIdentity?.institution?.institution_id}.svg`}
                />
              </StyledListItemAvatar>
              <StyledListItemText
                primary={loginIdentity?.institution?.institution_name}
                secondary={getListItemSecondaryText(accounts)}
              />
              <ListItemSecondaryAction>
                <StatusIcon
                  status={loginIdentityStatus}
                  isSuccessfulOnClick={() => handleViewClick(CurrentView.LOGIN_IDENTITY)}
                  isFailureOnClick={() => handleViewClick(CurrentView.LOGIN_IDENTITY)}
                  isLoadingOnClick={mutate}
                />
                <IconButton edge="end" aria-label="action" onClick={handleMenuClick} size="large">
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
            <AccountList
              accounts={accounts}
              handleViewClick={handleViewClick}
              dataStatus={loginIdentity?.login_identity?.product_status.accounts?.status ?? ''}
              dataStatusDetails={loginIdentity?.login_identity.product_status.accounts?.status_details ?? ''}
              isLoading={!accounts && !accountsError}
            />
            <ViewProductListItem onClick={viewStatements}>Statements</ViewProductListItem>
            <ViewProductListItem onClick={viewIdentity}>Identity</ViewProductListItem>
            <ViewProductListItem onClick={viewIncomeEstimation}>Income Estimation</ViewProductListItem>
          </List>
          <UnlinkDialog
            dialog={dialog}
            handleClose={handleClose}
            view={view}
            jsonViewableResponse={{
              accounts,
              identity,
              loginIdentity,
              loginIdentityHistory,
              transactions,
              statementsList,
              income,
            }}
          />
          <StyledMenu
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose}
            handleViewClick={handleViewClick}
            sync={mutate}
            showUnlinkConfirm={showUnlinkConfirm}
          />
        </RootContainer>
      </div>
    </>
  );
}
