import React, { useEffect, useState } from 'react';

import { MoreVert, AttachMoney } from '@mui/icons-material';
import { List, ListItemSecondaryAction, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { commonStyle } from '../components/commonStyle';
import ErrorSnack from '../components/ErrorSnack';
import DropDownMenu, { CurrentDialog } from '../components/Income/DropDownMenu';
import IncomeEstimationTable from '../components/Income/IncomeEstimationTable';
import JSONViewerDialogCommon from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import StatusIcon from '../components/StatusIcon';
import {
  StyledAvatar,
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledRootContainer,
} from '../components/styledComponents';
import TopBar from '../components/TopBar';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useValidToken from '../hooks/useValidToken';
import { getApiClient, getValue, isAuthorizationError } from '../services';
import { isProductEndStatus } from './Utilities';

export default function IncomeEstimation(): JSX.Element {
  const classes = commonStyle();
  const history = useHistory();
  const client = getApiClient();

  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);

  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showErrorMessage = () => {
    setSnackOpen(true);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = (view: CurrentDialog) => {
    setDialog(view);
  };

  const userToken = String(getValue('userToken'));
  const { validToken } = useValidToken(userToken);
  const { data: loginIdentity, error: loginIdentityError, mutate: mutateLoginIdentity } = useLoginIdentityId(userToken);
  const loginIdentityStatus = loginIdentity?.login_identity.status ?? '';
  const incomeStatus = loginIdentity?.login_identity.product_status.income_estimation?.status ?? '';
  const incomeStatusDetail = loginIdentity?.login_identity.product_status.income_estimation?.status_details ?? '';

  const {
    data: incomeResponse,
    error: incomeResponseError,
    mutate: mutateIncomeResponse,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.income_estimation?.status ?? '')
      ? [`/income`, userToken]
      : null,
    (_: string, token: string) => client.getIncomeEstimation(token),
  );

  const incomeLoading = !incomeResponse && !incomeResponseError;
  const loading = incomeLoading;

  const mutate = () => {
    mutateLoginIdentity();
    mutateIncomeResponse();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errors = [incomeResponseError, loginIdentityError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [incomeResponseError, loginIdentityError, history]);

  // To clean up some conditional rendering

  let incomeEstimation = undefined;
  let totalAvgIncome = '';
  let monthlyIncome = undefined;
  if (incomeResponse && incomeResponse?.income && incomeResponse?.income?.length > 0) {
    incomeEstimation = incomeResponse?.income[0]?.income_total?.estimated_monthly_income;
    if (incomeEstimation) {
      totalAvgIncome = `Estimated Total Monthly Income: ${incomeEstimation?.amount.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}(${incomeEstimation?.currency})`;
      monthlyIncome = incomeResponse?.income[0]?.income_total?.monthly_history;
    }
  }

  return (
    <>
      <div className={classes.main}>
        <StyledRootContainer>
          <TopBar title="IncomeTotal" showBackButton={true} />
          <LinkLogo title="Income Total" component={AttachMoney} />
          <List>
            <StyledListItem sx={{ height: '80px' }} key={0} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={loginIdentity?.institution?.institution_id}
                  src={`/img/square/${loginIdentity?.institution?.institution_id}.svg`}
                />
              </StyledListItemAvatar>
              <StyledListItemText
                sx={{ wordWrap: 'break-word' }}
                primary={loginIdentity?.institution?.institution_name}
                secondary={<Typography>{totalAvgIncome}</Typography>}
              />
              <ListItemSecondaryAction>
                <StatusIcon status={loginIdentityStatus} />
                <IconButton
                  disabled={
                    !isProductEndStatus(loginIdentity?.login_identity.product_status.income_estimation?.status ?? '') ||
                    loading
                  }
                  edge="end"
                  aria-label="action"
                  onClick={handleMenuClick}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          </List>

          <IncomeEstimationTable
            incomeStatus={incomeStatus}
            incomeStatusDetails={incomeStatusDetail}
            isLoading={incomeLoading}
            incomeEstimate={incomeEstimation}
            monthlyIncome={monthlyIncome}
          />

          <JSONViewerDialogCommon
            open={dialog === CurrentDialog.INCOME_JSON}
            json={incomeResponse}
            onClose={handleClose}
          />
          <DropDownMenu anchorElement={anchorEl} onViewClick={handleViewClick} onClose={handleMenuClose} />
        </StyledRootContainer>
      </div>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
