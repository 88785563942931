import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export function ErrorTableBody({ product, colSpan }: { product: string; colSpan?: number }) {
  return (
    <TableBody>
      <TableRow>
        {colSpan ? (
          <TableCell align="center" colSpan={colSpan}>
            Error fetching {product}.
          </TableCell>
        ) : (
          <TableCell align="center">Error fetching {product}.</TableCell>
        )}
      </TableRow>
    </TableBody>
  );
}

export function FetchingTableBody({ product, colSpan }: { product: string; colSpan?: number }) {
  return (
    <TableBody>
      <TableRow>
        {colSpan ? (
          <TableCell align="center" colSpan={colSpan}>
            Fetching {product}. Please wait...
          </TableCell>
        ) : (
          <TableCell align="center">Fetching {product}. Please wait...</TableCell>
        )}
      </TableRow>
    </TableBody>
  );
}

export function EmptyTableBody({ product, colSpan }: { product: string; colSpan?: number }) {
  return (
    <TableBody>
      <TableRow>
        {colSpan ? (
          <TableCell align="center" colSpan={colSpan}>
            No {product} to show
          </TableCell>
        ) : (
          <TableCell align="center">No {product} to show</TableCell>
        )}
      </TableRow>
    </TableBody>
  );
}

export function ProductNotSupported({ product, colSpan }: { product: string; colSpan?: number }) {
  return (
    <TableBody>
      <TableRow>
        {colSpan ? (
          <TableCell align="center" colSpan={colSpan}>
            {product} not supported
          </TableCell>
        ) : (
          <TableCell align="center">{product} not supported</TableCell>
        )}
      </TableRow>
    </TableBody>
  );
}
