import { ErrorMessage } from '@hookform/error-message';
import { TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import LinkLogo from '../components/LinkLogo';
import LinkPassword from '../components/LinkPassword';
import { RootContainer } from '../components/RootContainer';
import useAPIClient from '../hooks/useClient';
import { setValue, useClearLocalStorage } from '../services';

export const useStyles = makeStyles(() => ({
  loginForm: {
    padding: '6vh 10px 10px 10px',
  },
  textInput: {
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#FFFFFF',
    height: '46px',
    '& input': {
      height: '9px',
    },
    '& label': {
      color: '#ABABAB',
      top: '-5px',
    },
    autoCapitalize: 'none',
    autoCorrect: 'off',
  },
  error: {
    color: '#E20404',
    margin: 0,
  },
}));

type Inputs = {
  username: string;
  password: string;
};

export default function Login(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const client = useAPIClient();

  const onSubmit = async (data: Inputs) => {
    try {
      const token = await client.login(data.username, data.password);
      setValue('userToken', token);
      history.push('/home');
    } catch (err) {
      console.error(err);
    }
  };

  useClearLocalStorage();
  const { register, errors, handleSubmit } = useForm<Inputs>();
  return (
    <RootContainer>
      <LinkLogo title="Finverse Demo App"></LinkLogo>
      <form noValidate autoComplete="off" className={classes.loginForm} onSubmit={handleSubmit(onSubmit)}>
        <ErrorMessage errors={errors} name="username" as="p" className={classes.error} />
        <TextField
          id="username"
          name="username"
          label="User ID"
          variant="outlined"
          className={classes.textInput}
          inputRef={register({ required: 'User ID is required' })}
          type="email"
          autoFocus
        />
        <ErrorMessage errors={errors} name="password" as="p" className={classes.error} />
        <LinkPassword id="password" label="Password" style={classes.textInput} register={register} />
        <Button fullWidth={true} variant="contained" color="primary" type="submit">
          Login
        </Button>
      </form>
    </RootContainer>
  );
}
