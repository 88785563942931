import { useCallback, useState, useMemo, useEffect } from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useLocation, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ErrorSnack from '../components/ErrorSnack';
import LinkLogo from '../components/LinkLogo';
import LinkModal from '../components/LinkModal';
import Loader from '../components/LoadingOverlay';
import { RootContainer } from '../components/RootContainer';
import { makeFvLink } from '../components/util/fvlink';
import { CreateMandate } from '../models';
import { getApiClient, isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';

// const userId = uuidv4();
interface SenderDetail {
  details_type: string;
  values: string[];
}

function getSampleMandateForBusiness() {
  const senderDetails: SenderDetail[] = [];
  return getSampleMandate('BUSINESS', 'Test Customer', senderDetails);
}

function getSampleMandateForIndividual() {
  const senderDetails: SenderDetail[] = [];
  return getSampleMandate('INDIVIDUAL', 'Test Customer', senderDetails);
}

function getSampleMandate(senderType: string, senderName: string, senderDetails: SenderDetail[]) {
  const startDate = new Date();
  const endDate = new Date();
  endDate.setMonth(endDate.getMonth() + 3);

  const mandate_details = {
    start_date: startDate.toISOString().split('T')[0],
    end_date: endDate.toISOString().split('T')[0],
    currency: 'HKD',
    sender_type: senderType,
    description: 'The data used for this payment mandate does not reflect real data',
  };

  const sender = { name: senderName, sender_reference_id: 'Demo App Account 123', external_user_id: uuidv4() };

  const mandate = {
    mandate_details,
    sender,
    sender_details: senderDetails,
  };
  return mandate;
}
const mock_recipient = {
  name: 'Demo App',
};

export default function CreatePaymentMandate(): JSX.Element {
  const [iframe, setIframe] = useState('');
  const [linkOpen, setLinkOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [mandateId, setMandateId] = useState('');
  const [loading, setLoading] = useState(false);

  const [mandate, setMandate] = useState(getSampleMandateForIndividual());

  const [mandateToShow, setMandateToShow] = useState({
    mandate_details: mandate.mandate_details,
    sender: mandate.sender,
    recipient: mock_recipient,
    sender_details: mandate.sender_details.reduce((acc, cur) => ({ ...acc, [cur.details_type]: cur.values }), {}),
  });

  const history = useHistory();
  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {
    // if the user selected BUSINESS then we need to override sender data
    if (query.get('sender_type') === 'BUSINESS') {
      setMandate(getSampleMandateForBusiness());
    }
  }, [query]);

  useEffect(() => {
    setMandateToShow({
      mandate_details: mandate.mandate_details,
      sender: mandate.sender,
      recipient: mock_recipient,
      sender_details: mandate.sender_details.reduce((acc, cur) => ({ ...acc, [cur.details_type]: cur.values }), {}),
    });
  }, [mandate]);

  const receiveMessage = (message: any) => {
    if (message.data === 'close') {
      setLinkOpen(false);
    }
    if (message.data === 'success') {
      setLinkOpen(false);
      // Need to check for a valid mandateId
      // If we don't a call will be made when the mandateId is the default value
      mandateId.length > 0 && history.push(`/mandate/${mandateId}`);
    }
  };
  window.addEventListener('message', receiveMessage, false);

  const openLink = async (mandateId: string) => {
    setSnackOpen(false);
    const userToken = getValue('userToken');
    const linkURI = await getApiClient().getMandateLink(String(userToken), {
      mandate_id: mandateId,
      link_customizations: {
        link_mode: 'test',
      },
    });
    setIframe(makeFvLink(linkURI));
    setLinkOpen(true);
  };

  const handleClick = async () => {
    try {
      setSnackOpen(false);
      setLoading(true);
      const { mandate_id } = await getApiClient().createMandate(
        String(getValue('userToken')),
        mandate as unknown as CreateMandate,
      );
      setMandateId(mandate_id);
      await openLink(mandate_id);
    } catch (err) {
      setLoading(false);
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        setSnackOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = useCallback(() => {
    setLinkOpen(false);
    setSnackOpen(false);
  }, []);

  const handleErrorRetry = useCallback(async () => {
    if (typeof mandateId === 'string' && mandateId !== '') {
      // if mandate id exists open link
      await openLink(mandateId);
    } else {
      // Rerun the entire process again as we dont have mandate id
      await handleClick();
    }
  }, [mandateId]);

  const titleCase = (input: string) =>
    input
      .split('_')
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(' ');

  return (
    <RootContainer>
      <LinkLogo title="Finverse Demo App" subTitle="Setup Payment Mandate" />
      {Object.entries(mandateToShow).map(([keys, values]) => (
        <Table
          aria-label="a table with details of the payment mandate to be setup"
          sx={{ marginBottom: 4, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}
          key={keys}
        >
          <TableRow>
            <TableCell component="th" scope="row" colSpan={2} sx={{ borderBottom: 'none' }} align="center">
              {titleCase(keys)}
            </TableCell>
          </TableRow>
          {Object.entries(values).map(([subKey, subVal]) => (
            <TableRow key={subKey}>
              <TableCell component="th" scope="row" sx={{ width: '300px' }}>
                {titleCase(subKey)}
              </TableCell>
              <TableCell>
                {typeof subVal === 'object' ? JSON.stringify(subVal, null, 1) : subVal.toString().replaceAll('_', '')}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ))}
      <Button variant="contained" onClick={handleClick} sx={{ marginBottom: 4 }} disabled={loading}>
        Setup Payment Mandate
      </Button>
      <Loader loading={loading}></Loader>
      <LinkModal url={iframe} linkOpen={linkOpen} handleClose={handleClose} />
      <ErrorSnack open={snackOpen} handleClose={handleClose} retry={handleErrorRetry} />
    </RootContainer>
  );
}
