import { useState } from 'react';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type registerParams = {
  required: string;
};

type Props = {
  id: string;
  register: (params: registerParams) => (ref: any) => void;
  style: string;
  label: string;
};

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    '& svg': {
      color: '#ABABAB',
    },
  },
}));

export default function LinkPassword({ id, register, style, label }: Props): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      id={id}
      name={id}
      label={label}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      className={style}
      inputRef={register({ required: `${label} is required` })}
      InputProps={{
        endAdornment: (
          <InputAdornment className={classes.icon} position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} size="large">
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
