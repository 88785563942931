import { ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledListItemText = styled(ListItemText)(() => ({
  '& span': {
    fontSize: '15px',
    color: '#201F24',
    fontWeight: '800',
  },
  '& p': {
    fontSize: '14px',
    color: '#A1A6C1',
  },
})) as typeof ListItemText;

export default StyledListItemText;
