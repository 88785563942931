import { useMemo } from 'react';

import {
  AccountNumberResponse,
  BalanceHistoryResponse,
  Institution,
  LoginIdentity,
  TransactionsResponse,
} from '../../models';
import JSONViewerDialogCommon from '../JSONViewerDialog';

export enum CurrentDialog {
  NONE = 0,
  TXN_JSON,
  ACC_NUM_JSON,
  BAL_HIST_JSON,
}

type Props = {
  currentTransactionId: string;
  dialog: CurrentDialog;

  accountTransactions: TransactionsResponse | null | undefined;
  accountNumber: AccountNumberResponse | null | undefined;
  balanceHistory: BalanceHistoryResponse | null | undefined;

  onClose: () => void;
};

export default function TransactionsBalanceHistoryJSONViewerDialog({
  currentTransactionId,
  dialog,
  accountTransactions,
  accountNumber,
  balanceHistory,
  onClose: handleClose,
}: Props): JSX.Element {
  const transactionJson = useMemo((): TransactionsResponse | undefined | null => {
    if (currentTransactionId !== '') {
      const currTransaction = accountTransactions?.transactions.filter(
        (transaction) => transaction.transaction_id === currentTransactionId,
      );
      return {
        accounts: accountTransactions?.accounts ? accountTransactions?.accounts : [],
        login_identity: accountTransactions?.login_identity as LoginIdentity,
        institution: accountTransactions?.institution as Institution,
        transactions: currTransaction ? currTransaction : [],
        total_transactions: accountTransactions?.total_transactions ?? 0,
      };
    }
    return accountTransactions;
  }, [currentTransactionId, accountTransactions]);

  const accNumJson = useMemo(() => accountNumber, [accountNumber]);
  const balanceHistoryJson = useMemo(() => balanceHistory, [balanceHistory]);

  return (
    <>
      <JSONViewerDialogCommon open={dialog === CurrentDialog.TXN_JSON} json={transactionJson} onClose={handleClose} />
      {/* Account number Dialog */}
      <JSONViewerDialogCommon open={dialog === CurrentDialog.ACC_NUM_JSON} json={accNumJson} onClose={handleClose} />
      <JSONViewerDialogCommon
        open={dialog === CurrentDialog.BAL_HIST_JSON}
        json={balanceHistoryJson}
        onClose={handleClose}
      />
    </>
  );
}
