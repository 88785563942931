import { ArrowForwardIos } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import { AccountList, AccountsResponse, Error as APIError, IncomeResponse } from '../../models';
import { CurrentView } from '../../routes/User';
import { accountStatusDetailsLegit, incomeStatusDetailsLegit, isFailure, isNotSupported } from '../../routes/Utilities';
import { isAuthorizationError } from '../../services/api';
import { isAPIError } from '../../services/typeGuards';
import { StyledListItem } from '../styledComponents';
import { ProductNotSupported } from '../TableBody';
import getLocalAccounts from '../util/getAccountHelper';
import IncomeAccountListItem from './IncomeAccountListItem';

interface Props {
  accounts: AccountsResponse | APIError | undefined;
  income: IncomeResponse | APIError | undefined;
  handleViewClick: (view: CurrentView) => void;
  accountDataStatus: string;
  accountDataStatusDetails: string;
  incomeDataStatus: string;
  incomeDataStatusDetails: string;
  isLoading: boolean;
  className?: string;
}

const IncomeAccountList = ({
  accounts,
  income,
  accountDataStatus,
  accountDataStatusDetails,
  incomeDataStatus,
  incomeDataStatusDetails,
  isLoading,
  handleViewClick,
  className,
}: Props): JSX.Element => {
  const accountList: AccountList[] = [];
  const history = useHistory();

  const viewIncomeForAccountId = async (accountId: string) => {
    try {
      history.push(`/income/${accountId}`);
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        handleViewClick(CurrentView.INCOME);
      }
    }
  };

  // to store the list of accounts that has already been processed
  const processedAccounts = new Map<string, boolean>();

  if (isAPIError(accounts)) {
    return <ErrorListItem error={accounts} className={className} />;
  }
  if (isAPIError(income)) {
    return <ErrorListItem error={income} className={className} />;
  }
  if (isFailure(accountDataStatus)) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> Error fetching Accounts for income estimation</Typography>
      </StyledListItem>
    );
  }

  if (isNotSupported(accountDataStatus)) {
    return <ProductNotSupported product="Accounts" />;
  }

  if (accountStatusDetailsLegit(accountDataStatusDetails) === false || isLoading) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> Fetching Accounts. Please wait... </Typography>
      </StyledListItem>
    );
  }
  if (isFailure(incomeDataStatus)) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> Error fetching income estimation</Typography>
      </StyledListItem>
    );
  }

  if (isNotSupported(incomeDataStatus)) {
    return <ProductNotSupported product="Income Estimation" />;
  }

  if (incomeStatusDetailsLegit(incomeDataStatusDetails) === false || isLoading) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> Fetching Income. Please wait... </Typography>
      </StyledListItem>
    );
  }

  if (accounts === undefined) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> No accounts data to show </Typography>
      </StyledListItem>
    );
  }
  if (income === undefined) {
    return (
      <StyledListItem className={className} component={Paper}>
        <Typography> No income data to show </Typography>
      </StyledListItem>
    );
  }

  const localAccounts = getLocalAccounts(accounts);

  // Go through each account and then
  localAccounts.forEach((accItem) => {
    const accList = accItem as AccountList;
    if (
      income?.income?.[0].income_streams?.find((item) => {
        return item.account_id === accItem.account_id;
      })
    ) {
      if (accItem.is_parent) {
        // if the account is a parent account
        // go through the list of accounts to find the children account
        accList.children = localAccounts.filter((a) => {
          // Check that it is not a parent, but shares the same groupId
          if (!a.is_parent && a.group_id === accItem.group_id) {
            processedAccounts.set(a.account_id, true); // Mark the child as processed
            return true;
          }
        });

        processedAccounts.set(accList.account_id, true); // Mark the parent as processed
        accountList.push(accList);
      } else if (!processedAccounts.get(accItem.account_id)) {
        // Not a parent account, and not a child account either. Add straight to accountList
        // Note: We know it is not a child account, since we sort the parents first initially
        // so when we process them, their children would be marked in `processedAccounts`
        processedAccounts.set(accList.account_id, true); // Mark the standalone as processed
        accountList.push(accList);
      }
    }
  });

  return (
    <Paper className={className}>
      {accountList.map((account, index) => (
        <IncomeAccountListItem
          key={index}
          account={account}
          income={income}
          index={index}
          viewIncome={viewIncomeForAccountId}
        />
      ))}
    </Paper>
  );
};

const ErrorListItem = ({ error, className }: { error: APIError; className?: string }): JSX.Element => {
  return (
    <StyledListItem className={className} component={Paper}>
      <Typography variant="subtitle1">
        <ArrowForwardIos fontSize="small" />
        {error.message}
        <ArrowForwardIos fontSize="small" />
      </Typography>
    </StyledListItem>
  );
};

export default IncomeAccountList;
