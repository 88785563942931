import JSONPretty from 'react-json-pretty';

import 'react-json-pretty/themes/1337.css';
import { isAPIError } from '../services/typeGuards';

type Props = {
  json: any;
};

export default function JSONViewer({ json = { message: 'The product is being fetched...' } }: Props): JSX.Element {
  if (isAPIError(json)) {
    // The errors returned by demo app are not the correct format for API errors thus it whill show
    // the entire message currently
    const data = { message: json.message };
    return <JSONPretty data={data} />;
  }
  return <JSONPretty data={json} />;
}
