import { Receipt } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';

import { StyledMenu, StyledMenuItem } from '../StyledMenu';

export enum CurrentDialog {
  NONE = 0,
  INCOME_JSON,
}

type Props = {
  anchorElement: HTMLElement | null;

  onViewClick: (view: CurrentDialog) => void;
  onClose: () => void;
};

export default function DropDownMenu({
  anchorElement,
  onViewClick: handleViewClick,
  onClose: handleClose,
}: Props): JSX.Element {
  return (
    <StyledMenu id="customized-menu" anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={handleClose}>
      <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.INCOME_JSON)}>
        <ListItemIcon>
          <Receipt fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="View Income Estimation (raw JSON)" />
      </StyledMenuItem>
    </StyledMenu>
  );
}
