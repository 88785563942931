import { Fade, Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: '0px',
      left: '0px',
      display: 'flex',
      placeContent: 'center',
      placeItems: 'center',
      overflow: 'hidden',
    },
  }),
);

type AppProps = {
  loading: boolean;
};

export default function Loader({ loading }: AppProps): JSX.Element {
  const classes = useStyles();
  return (
    <Modal className={classes.loader} open={loading} closeAfterTransition>
      <Fade in={loading}>
        <CircularProgress size={50} />
      </Fade>
    </Modal>
  );
}
