import { useEffect, useMemo, useState } from 'react';

import { Payment, Receipt, MoreVert } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';

import ErrorSnack from '../components/ErrorSnack';
import JSONViewerDialogCommon from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import { RootContainer } from '../components/RootContainer';
import StatusIcon from '../components/StatusIcon';
import { StyledMenu, StyledMenuItem } from '../components/StyledMenu';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { GetPaymentInstructionResponse } from '../models/index';
import { isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';
import { isAPIError } from '../services/typeGuards';
import { isEndStatus } from './Utilities';

const ListItemButtonStyle = {
  height: '56px',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #CFD1DC',
  '& img': {
    objectFit: 'contain',
  },
  '& svg': {
    verticalAlign: 'text-bottom',
  },
};
const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '15px',
    color: '#201F24',
    fontWeight: '800',
  },
  '& p': {
    fontSize: '14px',
    color: '#A1A6C1',
  },
});

export enum CurrentDialog {
  NONE = 0,
  PAYMENT_INSTRUCTION_JSON,
}

export default function PaymentInstructionDetails(): JSX.Element {
  const history = useHistory();
  const client = useAPIClient();

  const { paymentInstructionId } = useParams<{ paymentInstructionId: string }>();
  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);
  const { data: loginIdentity, error: loginIdentityError, mutate: mutateLoginIdentity } = useLoginIdentityId(token);
  const loginIdentityStatus = loginIdentity?.login_identity.status ?? '';

  const {
    data: paymentInstruction,
    error: paymentInstructionError,
    mutate: mutatePaymentInstruction,
  } = useSWR<GetPaymentInstructionResponse>(
    isEndStatus(loginIdentityStatus)
      ? [`/payments/instruction/${paymentInstructionId}`, token, paymentInstructionId]
      : null,
    (_: string, token: string, paymentInstructionId: string) =>
      client.getPaymentInstruction(token, paymentInstructionId),
  );
  const loading = !paymentInstruction && !paymentInstructionError;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const showErrorMessage = () => {
    setSnackOpen(true);
  };
  const handleViewClick = (dialog: CurrentDialog) => {
    setDialog(dialog);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const listItemSecondaryText = useMemo((): string => {
    if (isAPIError(paymentInstruction)) return paymentInstruction.message;
    if (paymentInstruction == null || paymentInstruction.payment_instruction == null)
      return 'Fetching payment instruction...';
    return '';
  }, [paymentInstruction]);

  const mutate = () => {
    mutateLoginIdentity();
    mutatePaymentInstruction();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errors = [loginIdentityError, paymentInstructionError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [paymentInstructionError, loginIdentityError, history]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RootContainer>
          <LinkLogo title="Payment Instruction Details" component={Payment} />
          <List>
            <ListItemButton
              component="a"
              key={loginIdentity?.institution?.institution_id}
              // cannot use styled api here because the types get overridden because of some styled & material ui types
              sx={ListItemButtonStyle}
            >
              <ListItemAvatar sx={{ minWidth: '40px' }}>
                <Avatar
                  sx={{
                    width: '28px',
                    height: '28px',
                  }}
                  alt={loginIdentity?.institution?.institution_id}
                  src={`/img/square/${loginIdentity?.institution?.institution_id}.svg`}
                />
              </ListItemAvatar>
              <StyledListItemText
                primary={loginIdentity?.institution?.institution_name}
                secondary={listItemSecondaryText}
              />
              <ListItemSecondaryAction>
                <StatusIcon status={loginIdentityStatus} />
                <IconButton
                  disabled={!isEndStatus(loginIdentityStatus) || loading}
                  edge="end"
                  aria-label="action"
                  onClick={handleMenuClick}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Table aria-label="a table with details of the payment instruction" sx={{ marginBottom: 4 }}>
              <TableBody>
                {Object.entries(paymentInstruction?.payment_instruction ?? {}).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {
                        // Title case
                        key
                          .split('_')
                          .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                          .join(' ')
                      }
                    </TableCell>
                    <TableCell>{typeof value === 'object' ? JSON.stringify(value) : value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </List>
          <JSONViewerDialogCommon
            open={dialog === CurrentDialog.PAYMENT_INSTRUCTION_JSON}
            json={paymentInstruction}
            onClose={handleClose}
          />
          <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
            <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.PAYMENT_INSTRUCTION_JSON)}>
              <ListItemIcon>
                <Receipt fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="View Payment Details (raw JSON)" />
            </StyledMenuItem>
          </StyledMenu>
        </RootContainer>
      </Box>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
