import { ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

type AppProps = { title: string; showBackButton: boolean };

const useStyles = makeStyles(() => ({
  body: {
    display: 'flex',
  },
  back: {
    marginTop: '3px',
  },
}));

export default function TopBar({ showBackButton }: AppProps): JSX.Element {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <IconButton className={classes.back} color="inherit" onClick={() => history.goBack()} size="large">
        {showBackButton && history?.length > 0 && <ArrowBackIos />}
      </IconButton>
    </div>
  );
}
