import { useMemo } from 'react';

import { ArrowForwardIos } from '@mui/icons-material';
import { ListItemText, Paper, Typography } from '@mui/material';

import { StyledListItem } from '../styledComponents';

interface Props {
  onClick: () => void;

  children: JSX.Element | string;
  amount: number;
  currency: string;
  className?: string;
}

export default function ViewProductListItemWithAmount({
  onClick: handleClick,
  children,
  amount,
  currency,
}: Props): JSX.Element {
  const hasCurrency = useMemo(() => currency !== '', [currency]);

  return (
    <StyledListItem sx={{ marginBottom: '1rem' }} button component={Paper} onClick={handleClick}>
      <ListItemText>
        <Typography variant="subtitle1">
          {children} <ArrowForwardIos fontSize="small" />
        </Typography>
      </ListItemText>
      <ListItemText sx={{ textAlign: 'right' }}>
        {hasCurrency && (
          <Typography variant="body1">{`${amount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })} ${currency}`}</Typography>
        )}
      </ListItemText>
    </StyledListItem>
  );
}
