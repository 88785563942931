import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';

type AppProps = {
  url: string;
  linkOpen: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  containerDesktop: {
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  linkBase: {
    margin: 'auto',
  },
  linkDesktop: {
    overflow: 'hidden',
    minHeight: '720px', // 16:9 ratio
    height: '100%',
    maxHeight: '720px',
    width: '405px',
    minWidth: '405px',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  linkMobile: {
    height: '100%',
    width: '100%',
  },
}));

export default function LinkModal({ url, linkOpen, handleClose }: AppProps): JSX.Element {
  const isMobile = useCallback((): boolean => {
    return (
      window.matchMedia('(max-width: 760px)').matches &&
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  }, []);
  const isPortrait = window.innerWidth > window.innerHeight ? false : true;
  const classes = useStyles();

  const containerStyle = `${classes.container} ${isMobile() && isPortrait ? '' : classes.containerDesktop}`;
  const linkStyle = `${classes.linkBase} ${isMobile() && isPortrait ? classes.linkMobile : classes.linkDesktop}`;

  return (
    <Modal open={linkOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={linkOpen}>
        <Box className={containerStyle}>
          <Box className={linkStyle}>
            <iframe
              src={url}
              style={{ border: 'none', display: 'block', height: '100%', width: '100%', zoom: '100%' }}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
