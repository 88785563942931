import React, { useEffect, useMemo, useState } from 'react';

import { MoreVert, AccountTree } from '@mui/icons-material';
import { List, ListItemSecondaryAction, IconButton, Tabs, Tab } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

import { commonStyle } from '../components/commonStyle';
import ErrorSnack from '../components/ErrorSnack';
import LinkLogo from '../components/LinkLogo';
import StatusIcon from '../components/StatusIcon';
import {
  StyledAvatar,
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledRootContainer,
} from '../components/styledComponents';
import TabPanel from '../components/TabPanel';
import TopBar from '../components/TopBar';
import BalanceHistoriesTable from '../components/TransactionsBalanceHistory/BalanceHistoriesTable';
import DropDownMenu from '../components/TransactionsBalanceHistory/DropDownMenu';
import JSONViewerDialog, { CurrentDialog } from '../components/TransactionsBalanceHistory/JSONViewerDialog';
import TransactionsTable from '../components/TransactionsBalanceHistory/TransactionsTable';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { TransactionsResponse, AccountsResponse, Account } from '../models';
import { getValue, isAuthorizationError } from '../services';
import { isProductEndStatus } from './Utilities';

const MAX_ROW_PER_PAGE = 10; // The maximum number of rows in one signal table view

type ParamTypes = {
  accountId: string;
};

export enum CurrentView {
  TRANSACTIONS = 0,
  BALANCE_HISTORY,
}

interface Props {
  topLevelAccounts?: AccountsResponse;
}

export default function TransactionBalanceHistory({ topLevelAccounts }: Props): JSX.Element {
  const classes = commonStyle();
  const history = useHistory();
  const client = useAPIClient();
  const { accountId } = useParams<ParamTypes>();

  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);
  const { data: loginIdentity, error: loginIdentityError, mutate: mutateLoginIdentity } = useLoginIdentityId(token);
  const loginIdentityStatus = loginIdentity?.login_identity.status ?? '';
  const transactionStatus = loginIdentity?.login_identity.product_status.online_transactions?.status ?? '';
  const transactionStatusDetails =
    loginIdentity?.login_identity.product_status.online_transactions?.status_details ?? '';
  const balanceHistoryStatus = loginIdentity?.login_identity.product_status.balance_history?.status ?? '';
  const balanceHistoryStatusDetails =
    loginIdentity?.login_identity.product_status.balance_history?.status_details ?? '';
  const loginIdentityId = loginIdentity?.login_identity.login_identity_id ?? '';

  const {
    data: accountNumber,
    error: accountNumberError,
    mutate: mutateAccountNumber,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.account_numbers?.status ?? '')
      ? [`account_numbers/${loginIdentityId}/${accountId}`, token, accountId, loginIdentityId]
      : null,
    (_: string, token: string, accountId: string, loginIdentityId) =>
      client.getAccountNumberByAccountId(token, accountId, loginIdentityId),
  );
  const {
    data: balanceHistory,
    error: balanceHistoryError,
    mutate: mutateBalanceHistory,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.balance_history?.status ?? '')
      ? [`balance_history/${loginIdentityId}/${accountId}`, token, accountId, loginIdentityId]
      : null,
    (_: string, token: string, accountId: string, loginIdentityId) =>
      client.getBalanceHistory(token, loginIdentityId, accountId),
  );
  const {
    data: accountTransactions,
    error: accountTransactionsError,
    mutate: mutateAccountTransactions,
    size,
    setSize,
  } = useSWRInfinite<TransactionsResponse>(
    (index) =>
      isProductEndStatus(loginIdentity?.login_identity.product_status.online_transactions?.status ?? '')
        ? [`transactions/${accountId}`, token, accountId, index]
        : null,

    (_: string, token: string, accountId: string, index: number) => {
      return client.getTransactionsForAccountId(token, accountId, index * MAX_ROW_PER_PAGE, MAX_ROW_PER_PAGE); // multiply index (i.e. page number - 1) with MAX_ROWS to get correct offset
    },
  );

  const balanceHistoryLoading = balanceHistory === undefined && !balanceHistoryError;
  const accountNumberLoading = !accountNumber && !accountNumberError;
  const transactionsLoading = !accountTransactions && !accountTransactionsError;
  const transactionsIsLoadingMore =
    transactionsLoading || (size > 0 && accountTransactions && typeof accountTransactions[size - 1] === 'undefined');
  const loading = balanceHistoryLoading || accountNumberLoading || transactionsLoading || transactionsIsLoadingMore;

  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [currTransactionId, setCurrTransactionId] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);

  const [currentTab, setCurrentTab] = useState(CurrentView.TRANSACTIONS);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const currAccount = useMemo<string | Account>(
    () =>
      topLevelAccounts?.accounts?.find((acc) => acc.account_id === accountId) ??
      accountTransactions?.[0].accounts[0] ??
      'UNAVAILABLE',
    [topLevelAccounts?.accounts, accountTransactions, accountId],
  );

  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showErrorMessage = () => {
    setSnackOpen(true);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnChangeTab = (_event: React.ChangeEvent<unknown>, newIndex: unknown) => {
    if (typeof newIndex === 'number') setCurrentTab(newIndex);
  };

  const handleViewClick = (dialog: CurrentDialog, transactionId?: string) => {
    setDialog(dialog);
    setCurrTransactionId(transactionId ?? '');
  };

  const paginationOnChange = async (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPageNumber: number,
  ) => {
    setSize(newPageNumber + 1);
    // Go to new page
    setCurrentPageNumber(newPageNumber);
  };

  // To clean up some conditional rendering

  const linkLogoText = useMemo(
    () => (typeof currAccount === 'string' ? currAccount : `Transaction details for ${currAccount.account_name}`),
    [currAccount],
  );

  const balanceText = useMemo(
    () =>
      typeof currAccount === 'string'
        ? currAccount
        : `Balance ${currAccount.balance.currency} ${currAccount.balance.value}`,
    [currAccount],
  );

  const accountType = useMemo(() => {
    if (accountNumber === null) {
      return 'Type: UNAVAILABLE - Subtype: UNAVAILABLE';
    }
    if (accountNumber === undefined) {
      return 'Type: Loading - Subtype: Loading';
    }
    return `Type: ${accountNumber.account.account_type.type} - Subtype: ${accountNumber.account.account_type.subtype}`;
  }, [accountNumber]);

  const accNumberText = useMemo(() => {
    if (accountNumber === null) {
      return 'Account Number: UNAVAILABLE';
    }
    if (accountNumber === undefined) {
      return 'Account Number: Loading';
    }
    return `Account Number: ${accountNumber.account_number.raw}`;
  }, [accountNumber]);

  const a11yProps = (index: number) => {
    const ids = ['transactions', 'balance history'];
    return {
      id: ids[index] ?? `transactions-balance-history-${index}`,
      'aria-controls': `transactions-balance-history-panel-${index}`,
    };
  };

  const mutate = () => {
    mutateLoginIdentity();
    mutateAccountNumber();
    mutateAccountTransactions();
    mutateBalanceHistory();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history, currentPageNumber]);

  useEffect(() => {
    const errors = [loginIdentityError, accountNumberError, balanceHistoryError, accountTransactionsError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [accountNumberError, loginIdentityError, balanceHistoryError, accountTransactionsError, history]);

  return (
    <>
      <div className={classes.main}>
        <StyledRootContainer>
          <TopBar title="Transactions" showBackButton={true} />
          <LinkLogo title={linkLogoText} component={AccountTree} />
          <List>
            <StyledListItem sx={{ height: '100px' }} key={0} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={accountTransactions?.[0].institution?.institution_id}
                  src={`/img/square/${accountTransactions?.[0].institution?.institution_id}.svg`}
                />
              </StyledListItemAvatar>
              <StyledListItemText
                sx={{ wordWrap: 'break-word' }}
                primary={
                  accountTransactions
                    ? accountTransactions?.[0].institution?.institution_name
                    : topLevelAccounts?.institution.institution_name
                }
                secondary={
                  <>
                    {balanceText}
                    <br />
                    {accNumberText}
                    <br />
                    {accountType}
                  </>
                }
              />
              <ListItemSecondaryAction>
                <StatusIcon status={loginIdentityStatus} />
                <IconButton
                  disabled={
                    !(
                      isProductEndStatus(loginIdentity?.login_identity.product_status.account_numbers?.status ?? '') ||
                      isProductEndStatus(loginIdentity?.login_identity.product_status.balance_history?.status ?? '') ||
                      isProductEndStatus(
                        loginIdentity?.login_identity.product_status.online_transactions?.status ?? '',
                      ) ||
                      loading
                    )
                  }
                  edge="end"
                  aria-label="action"
                  onClick={handleMenuClick}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          </List>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            value={currentTab}
            onChange={handleOnChangeTab}
            className={classes.tabContainer}
          >
            <Tab label="Transactions" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Balance History" {...a11yProps(1)} className={classes.tab} />
          </Tabs>
          <TabPanel isVisible={currentTab === CurrentView.TRANSACTIONS} index={CurrentView.TRANSACTIONS}>
            <TransactionsTable
              txnStatus={transactionStatus}
              txnStatusDetails={transactionStatusDetails}
              isLoading={(transactionsLoading || transactionsIsLoadingMore) ?? false}
              transactions={accountTransactions?.[size - 1]?.transactions}
              onSearchIconClick={(transactionId) => handleViewClick(CurrentDialog.TXN_JSON, transactionId)}
              totalTransaction={accountTransactions?.[0].total_transactions}
              currentPageNumber={currentPageNumber}
              paginationOnChange={paginationOnChange}
            />
          </TabPanel>
          <TabPanel isVisible={currentTab === CurrentView.BALANCE_HISTORY} index={CurrentView.BALANCE_HISTORY}>
            <BalanceHistoriesTable
              balanceHistoryStatus={balanceHistoryStatus}
              balanceHistoryStatusDetails={balanceHistoryStatusDetails}
              isLoading={balanceHistoryLoading}
              balanceHistory={balanceHistory?.balance_history}
            />
          </TabPanel>
          <JSONViewerDialog
            currentTransactionId={currTransactionId}
            dialog={dialog}
            accountTransactions={accountTransactions?.[size - 1]}
            accountNumber={accountNumber}
            balanceHistory={balanceHistory}
            onClose={handleClose}
          />
          <DropDownMenu
            anchorElement={anchorEl}
            accountNumberViewable={!!accountNumber}
            balanceHistoryViewable={!!balanceHistory}
            onViewClick={handleViewClick}
            onClose={handleMenuClose}
          />
        </StyledRootContainer>
      </div>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
