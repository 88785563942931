import Container from '@mui/material/Container';
import styled from '@mui/styles/styled';

export const RootContainer = styled(Container)({
  paddingLeft: '5vw',
  paddingRight: '5vw',
  maxWidth: '800px',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
});
