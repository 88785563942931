import { Dialog, DialogContent } from '@mui/material';

import JSONViewer from './JSONViewer';

type Props = {
  json: unknown;
  open: boolean;
  onClose?: () => void;
};

export default function JSONViewerDialog({
  json,
  open,
  onClose = () => {
    null;
  },
}: Props): JSX.Element {
  return (
    <Dialog fullWidth open={open} onClose={onClose} aria-labelledby="form-dialog-title" id="json-viewer">
      <DialogContent>
        <JSONViewer json={json} />
      </DialogContent>
    </Dialog>
  );
}
