import { useState } from 'react';

import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { ClientProvider } from './contexts/ClientContext';
import { AccountsResponse, LoginIdentityResponse } from './models';
import GetToken from './routes/GetToken';
import Home from './routes/Home';
import Identity from './routes/Identity';
import IncomeEstimation from './routes/IncomeEstimation';
import IncomeEstimationByAccount from './routes/IncomeEstimationByAccount';
import IncomeEstimationMenu from './routes/IncomeEstimationMenu';
import Login from './routes/Login';
import MandateDetails from './routes/MandateDetails';
import PaymentDetails from './routes/PaymentDetails';
import PaymentInstructionDetails from './routes/PaymentInstructionDetails';
import Root from './routes/Root';
import SelectSenderType from './routes/SelectSenderType';
import SetupPaymentInstruction from './routes/SetupPaymentInstruction';
import SetupPaymentMandate from './routes/SetupPaymentMandate';
import Statement from './routes/Statement';
import TransactionBalanceHistory from './routes/TransactionBalanceHistory';
import User from './routes/User';
import './App.css';

function App(): JSX.Element {
  const [topLevelLoginIdentity, setTopLevelLoginIdentity] = useState<LoginIdentityResponse | undefined>();
  const [topLevelAccounts, setTopLevelAccounts] = useState<AccountsResponse | undefined>();

  return (
    <>
      <CssBaseline />
      <ClientProvider>
        <Router>
          <Switch>
            <Route path="/get-token">
              <GetToken />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            <Route path="/user">
              <User setTopLevelAccounts={setTopLevelAccounts} setTopLevelLoginIdentity={setTopLevelLoginIdentity} />
            </Route>
            <Route path="/transaction/:accountId">
              <TransactionBalanceHistory topLevelAccounts={topLevelAccounts} />
            </Route>
            <Route path="/statements">
              <Statement topLevelLoginIdentity={topLevelLoginIdentity} />
            </Route>
            <Route path="/identity">
              <Identity />
            </Route>
            <Route path="/income/total">
              <IncomeEstimation />
            </Route>
            <Route path="/income/:accountId">
              <IncomeEstimationByAccount topLevelAccounts={topLevelAccounts} />
            </Route>
            <Route path="/income">
              <IncomeEstimationMenu />
            </Route>
            <Route path="/payment/instruction">
              <SetupPaymentInstruction />
            </Route>
            <Route path="/payment/instruction/:paymentInstructionId">
              <PaymentInstructionDetails />
            </Route>
            <Route path="/mandate/:mandateId">
              <MandateDetails />
            </Route>
            <Route path="/mandate">
              <SetupPaymentMandate />
            </Route>
            <Route path="/payment/:paymentId">
              <PaymentDetails />
            </Route>
            <Route path="/select-sender-type">
              <SelectSenderType />
            </Route>
            <Route path="/">
              <Root />
            </Route>
          </Switch>
        </Router>
      </ClientProvider>
    </>
  );
}

export default App;
