import React, { useEffect, useMemo, useState } from 'react';

import { MoreVert, AttachMoney } from '@mui/icons-material';
import { List, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { commonStyle } from '../components/commonStyle';
import ErrorSnack from '../components/ErrorSnack';
import DropDownMenu, { CurrentDialog } from '../components/Income/DropDownMenu';
import IncomeAccountList from '../components/Income/IncomeAccountList';
import JSONViewerDialogCommon from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import StatusIcon from '../components/StatusIcon';
import {
  StyledAvatar,
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledRootContainer,
} from '../components/styledComponents';
import TopBar from '../components/TopBar';
import ViewProductListItemWithAmount from '../components/User/ViewProductListItemWithAmount';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useValidToken from '../hooks/useValidToken';
import { getApiClient, getValue, isAuthorizationError } from '../services';
import { isProductEndStatus } from './Utilities';

enum CurrentView {
  LOGIN_IDENTITY = 1,
  ACCOUNTS,
  TRANSACTIONS,
  CURR_TRANSACTIONS,
  LOGIN_IDENTITY_HISTORY,
  STATEMENTS,
  IDENTITY,
  INCOME,
}

export default function IncomeEstimation(): JSX.Element {
  const classes = commonStyle();
  const history = useHistory();
  const client = getApiClient();

  const [view, setView] = useState<CurrentView>(CurrentView.LOGIN_IDENTITY);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);

  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showErrorMessage = () => {
    setSnackOpen(true);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewClick = (view: CurrentDialog) => {
    setDialog(view);
  };

  const viewIncomeEstimationTotal = async () => {
    history.push('/income/total');
  };

  const handleViewIncomeTotalClick = (view: CurrentView) => {
    setView(view);
    setDialog(CurrentDialog.INCOME_JSON);
    handleIncomeMenuClose();
  };
  const handleIncomeMenuClose = () => {
    setAnchorEl(null);
  };

  const userToken = String(getValue('userToken'));
  const { validToken } = useValidToken(userToken);
  const { data: loginIdentity, error: loginIdentityError, mutate: mutateLoginIdentity } = useLoginIdentityId(userToken);
  const loginIdentityStatus = loginIdentity?.login_identity.status ?? '';

  const {
    data: incomeResponse,
    error: incomeResponseError,
    mutate: mutateIncomeResponse,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.income_estimation?.status ?? '')
      ? [`/income`, userToken]
      : null,
    (_: string, token: string) => client.getIncomeEstimation(token),
  );

  const {
    data: accounts,
    error: accountsError,
    mutate: mutateAccounts,
  } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.accounts?.status ?? '')
      ? ['/accounts', userToken]
      : null,
    (_: string, token: string) => client.getAccounts(token),
  );

  const incomeLoading = !incomeResponse && !incomeResponseError;
  const loading = incomeLoading;

  const mutate = () => {
    mutateLoginIdentity();
    mutateIncomeResponse();
    mutateAccounts();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errors = [incomeResponseError, loginIdentityError, accountsError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [incomeResponseError, loginIdentityError, accountsError, history]);

  const { incomeTotalAmount, incomeTotalCurrency } = useMemo(() => {
    if (incomeResponse?.income?.length > 0 && incomeResponse?.income[0]?.income_total?.estimated_monthly_income) {
      const incomeTotalAmount = incomeResponse.income[0].income_total.estimated_monthly_income.amount;
      const incomeTotalCurrency = incomeResponse.income[0].income_total.estimated_monthly_income.currency;
      return { incomeTotalAmount, incomeTotalCurrency };
    }
    return { incomeTotalAmount: 0, incomeTotalCurrency: '' };
  }, [incomeResponse]);

  return (
    <>
      <div className={classes.main}>
        <StyledRootContainer>
          <TopBar title="IncomeEstimation" showBackButton={true} />
          <LinkLogo title="Income Estimation" component={AttachMoney} />
          <List>
            <StyledListItem sx={{ height: '80px' }} key={0} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={loginIdentity?.institution?.institution_id}
                  src={`/img/square/${loginIdentity?.institution?.institution_id}.svg`}
                />
              </StyledListItemAvatar>
              <StyledListItemText
                sx={{ wordWrap: 'break-word' }}
                primary={loginIdentity?.institution?.institution_name}
              />
              <ListItemSecondaryAction>
                <StatusIcon status={loginIdentityStatus} />
                <IconButton
                  disabled={
                    !(
                      isProductEndStatus(
                        loginIdentity?.login_identity.product_status.income_estimation?.status ?? '',
                      ) && isProductEndStatus(loginIdentity?.login_identity.product_status.accounts?.status ?? '')
                    ) || loading
                  }
                  edge="end"
                  aria-label="action"
                  onClick={handleMenuClick}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          </List>

          <ViewProductListItemWithAmount
            onClick={viewIncomeEstimationTotal}
            amount={incomeTotalAmount}
            currency={incomeTotalCurrency}
          >
            Income Estimation Total
          </ViewProductListItemWithAmount>
          <StyledListItem key={'incomeEstimationTotal'} button component="a">
            <StyledListItemText
              sx={{ wordWrap: 'break-word', fontWeight: '400' }}
              primary={'Income Estimation By Accounts'}
            />
          </StyledListItem>
          <IncomeAccountList
            className={classes.productListItem}
            income={incomeResponse}
            accounts={accounts}
            handleViewClick={handleViewIncomeTotalClick}
            accountDataStatus={loginIdentity?.login_identity?.product_status.accounts?.status ?? ''}
            accountDataStatusDetails={loginIdentity?.login_identity.product_status.accounts?.status_details ?? ''}
            incomeDataStatus={loginIdentity?.login_identity?.product_status.income_estimation?.status ?? ''}
            incomeDataStatusDetails={
              loginIdentity?.login_identity.product_status.income_estimation?.status_details ?? ''
            }
            isLoading={loading}
          />

          <JSONViewerDialogCommon
            open={dialog === CurrentDialog.INCOME_JSON}
            json={incomeResponse}
            onClose={handleClose}
          />
          <DropDownMenu anchorElement={anchorEl} onViewClick={handleViewClick} onClose={handleMenuClose} />
        </StyledRootContainer>
      </div>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
