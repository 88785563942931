import { ArrowForwardIos } from '@mui/icons-material';
import { List, ListItem, ListItemText, Typography, Collapse } from '@mui/material';

import { AccountList, IncomeResponse, IncomeStream } from '../../models/index';
import { commonStyle } from '../commonStyle';
import { StyledListItem } from '../styledComponents';

interface Props {
  index: number;
  account: AccountList;
  income: IncomeResponse;
  viewIncome: (accountId: string) => Promise<void>;
}

const IncomeAccountListItem = (props: Props): JSX.Element => {
  const { index, account, income, viewIncome: viewIncomeForAccountId } = props;
  const classes = commonStyle();
  if (income?.income?.length && income?.income?.length <= 0) {
    return <></>;
  }
  const accIncome = income?.income?.[0].income_streams?.find((item) => {
    return item.account_id === account.account_id;
  });

  return (
    <>
      <StyledListItem key={index} button component="a" onClick={() => viewIncomeForAccountId(account.account_id)}>
        {accIncome && IncomeAccountListItemDetail(account, accIncome)}
      </StyledListItem>
      <Collapse in timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {account.children?.map((child, index) => {
            if (income?.income?.length && income.income.length <= 0) {
              return null;
            }
            const childAccIncome = income?.income?.[0].income_streams?.find((item) => {
              return item.account_id === child.account_id;
            });

            return (
              <ListItem
                className={classes.nestedListItem}
                key={index}
                button
                component="a"
                onClick={() => viewIncomeForAccountId(child.account_id)}
              >
                {childAccIncome && IncomeAccountListItemDetail(child as AccountList, childAccIncome)}
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const IncomeAccountListItemDetail = (account: AccountList, stream: IncomeStream): JSX.Element => {
  return (
    <>
      <ListItemText>
        <Typography variant="subtitle1">
          {account.account_name} <ArrowForwardIos fontSize="small" />
        </Typography>
      </ListItemText>
      <ListItemText style={{ textAlign: 'right' }}>
        <Typography variant="body1">
          {stream.estimated_monthly_income
            ? stream.estimated_monthly_income.amount.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })
            : Number(0).toLocaleString()}
          {stream.estimated_monthly_income?.currency}
          {` (est. mth. income)`}
        </Typography>
      </ListItemText>
    </>
  );
};

export default IncomeAccountListItem;
