// PRODUCT STATUS

export const accountsLegit = (accountsStatus: string): boolean => {
  return accountsStatus === 'ACCOUNTS_RETRIEVED';
};

export const transactionsLegit = (transactionsStatus: string): boolean => {
  return transactionsStatus === 'ONLINE_TRANSACTIONS_RETRIEVED';
};

export const statementsLegit = (statementsStatus: string): boolean => {
  return statementsStatus === 'STATEMENTS_RETRIEVED' || statementsStatus === 'STATEMENTS_RETRIEVAL_FAILED'; // If failed, we might still have some
};

export const identityLegit = (identityStatus: string): boolean => {
  return identityStatus === 'IDENTITY_RETRIEVED';
};

export const incomeLegit = (incomeStatus: string): boolean => {
  return incomeStatus === 'INCOME_ESTIMATION_COMPLETED' || incomeStatus === 'DATA_RETRIEVAL_COMPLETE';
};

export const isProductEndStatus = (productStatus: string) =>
  productStatus === 'SUCCESS' ||
  productStatus === 'ERROR' ||
  productStatus === 'PARTIAL_ERROR' ||
  productStatus === 'WARNING';

// PRODUCT STATUS DETAILS

export const accountStatusDetailsLegit = (accountStatusDetails: string): boolean =>
  accountStatusDetails === 'ACCOUNTS_RETRIEVED' || accountStatusDetails === 'ACCOUNTS_PARTIALLY_RETRIEVED';

export const transactionsStatusDetailsLegit = (txnStatusDetails: string): boolean => {
  return (
    txnStatusDetails === 'ONLINE_TRANSACTIONS_RETRIEVED' ||
    txnStatusDetails === 'ONLINE_TRANSACTIONS_PARTIALLY_RETRIEVED' ||
    txnStatusDetails === 'ONLINE_TRANSACTIONS_NOT_FOUND'
  );
};
export const balanceHistoryStatusDetailsLegit = (balanceHistoryDetails: string): boolean => {
  return (
    balanceHistoryDetails === 'BALANCE_HISTORY_RETRIEVED' ||
    balanceHistoryDetails == 'BALANCE_HISTORY_PARTIALLY_RETRIEVED'
  );
};

export const identityStatusDetailsLegit = (txnStatusDetails: string): boolean => {
  return txnStatusDetails === 'IDENTITY_RETRIEVED' || txnStatusDetails === 'IDENTITY_PARTIALLY_RETRIEVED';
};

export const incomeStatusDetailsLegit = (incomeStatusDetails: string): boolean => {
  return (
    incomeStatusDetails === 'INCOME_ESTIMATION_COMPLETED' ||
    incomeStatusDetails === 'INCOME_ESTIMATION_PARTIALLY_COMPLETED'
  );
};

// LOGIN IDENTITY STATUS
export const isEndStatus = (status: string): boolean => {
  return isFailure(status) || isSuccessful(status);
};

export const isSuccessful = (status: string): boolean => {
  return (
    status === 'DATA_RETRIEVAL_PARTIALLY_SUCCESSFUL' ||
    status === 'DATA_RETRIEVAL_COMPLETE' ||
    status === 'CONNECTION_COMPLETE' ||
    status === 'SUBMITTED' ||
    status === 'SUCCEEDED' ||
    status === 'EXECUTED'
  );
};

export const isFailure = (status: string): boolean => {
  return status === 'ERROR' || status === 'FAILED' || status === 'REVOKED';
};

export const isDataAvailable = (status: string): boolean => {
  return status === 'DATA_AVAILABLE';
};

export const isNotSupported = (status: string): boolean => {
  return status === 'NOT_SUPPORTED';
};

export const isNullOrUndefined = (val: unknown): boolean => {
  return val === null || val === undefined;
};
