import makeStyles from '@mui/styles/makeStyles';

export const commonStyle = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  listContainer: {
    paddingLeft: '5vw',
    paddingRight: '5vw',
    maxWidth: '800px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  productListItem: {
    marginBottom: '1rem',
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4),
    height: '56px',
    backgroundColor: '#F9F9F9',
    borderBottom: '1px solid #CFD1DC',
    '& img': {
      objectFit: 'contain',
    },
    '& svg': {
      verticalAlign: 'text-bottom',
    },
  },
  postDate: {
    color: '#999',
  },
  tabContainer: {
    backgroundColor: theme.palette.common.white,
  },
  tab: {
    minHeight: '60px',
  },
  viewLink: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
