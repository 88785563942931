import React, { useEffect, useState } from 'react';

import { CheckCircle, Error, Receipt, MoreVert, Description } from '@mui/icons-material';
import {
  Typography,
  CircularProgress,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogContent,
  MenuItem,
  ListItemIcon,
  Link,
} from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import withStyles from '@mui/styles/withStyles';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { commonStyle } from '../components/commonStyle';
import ErrorSnack from '../components/ErrorSnack';
import JSONViewer from '../components/JSONViewer';
import LinkLogo from '../components/LinkLogo';
import {
  StyledAvatar,
  StyledListItem,
  StyledListItemAvatar,
  StyledListItemText,
  StyledRootContainer,
  StyledTable,
  StyledTableCell,
} from '../components/styledComponents';
import TopBar from '../components/TopBar';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { LoginIdentityResponse, StatementsResponse } from '../models';
import { getValue, isAuthorizationError } from '../services';
import { isSuccessful, isFailure, isNotSupported, isNullOrUndefined, isProductEndStatus } from './Utilities';

enum CurrentDialog {
  NONE = 0,
  JSON,
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

type StatementTableProps = {
  message: string;
};

function StatementTableBody({ message }: StatementTableProps): JSX.Element {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center">{message}</TableCell>
      </TableRow>
    </TableBody>
  );
}

type TableBodyContentProps = {
  statementStatus: string;
  dataStatus: string;
  statementStatusDetails: string;
  loading: boolean;
  statementsList?: StatementsResponse;
  handleErrorMessage: () => void;
};
const TableBodyContent = ({
  dataStatus,
  loading,
  statementStatus,
  statementsList,
  statementStatusDetails,
  handleErrorMessage: showErrorMessage,
}: TableBodyContentProps) => {
  const classes = commonStyle();
  const history = useHistory();
  const client = useAPIClient();

  const openStatementLink = async (statementId: string) => {
    const userToken = getValue('userToken') ?? '';
    const validToken = await client.checkToken(userToken);
    if (!validToken) {
      history.push('/login');
    }

    try {
      const statementLink = await client.getStatementLinkById(userToken, statementId);
      const statementUrl = statementLink.statement_links[0].url;
      if (statementUrl) {
        const win = window.open(statementUrl, '_blank');
        if (win != null) {
          win.focus();
        }
      } else {
        showErrorMessage();
      }
    } catch (error) {
      showErrorMessage();
    }
  };

  if (isNotSupported(statementStatus)) {
    return <StatementTableBody message="Statements are not supported" />;
  } else if (isFailure(statementStatus)) {
    return <StatementTableBody message="Error fetching statements" />;
  } else if (statementStatusDetails === 'STATEMENTS_NOT_FOUND') {
    return <StatementTableBody message="No Statements to show" />;
  } else if (loading) {
    return (
      <>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
          </TableRow>
        </TableHead>
        <StatementTableBody message="Fetching Statements. Please wait..." />
      </>
    );
  } else {
    return (
      <TableBody>
        {isNullOrUndefined(statementsList?.statements) || statementsList?.statements.length === 0 ? (
          <TableRow>
            <TableCell align="center">No Statements to show</TableCell>
          </TableRow>
        ) : (
          statementsList?.statements.map((statement) => {
            return (
              <TableRow key={statement.id}>
                <TableCell>
                  {statement.name}
                  <Typography className={classes.viewLink} variant="caption" display="block" gutterBottom>
                    <Link
                      onClick={() => {
                        openStatementLink(statement.id);
                      }}
                    >
                      View Statement
                    </Link>
                  </Typography>
                </TableCell>
                <TableCell>{new Date(statement.date).toLocaleDateString()}</TableCell>
              </TableRow>
            );
          })
        )}
      </TableBody>
    );
  }
};

interface Props {
  topLevelLoginIdentity?: LoginIdentityResponse;
}

export default function Statement({ topLevelLoginIdentity }: Props): JSX.Element {
  const classes = commonStyle();
  const history = useHistory();
  const client = useAPIClient();

  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);
  const { data: loginIdentity, error: loginIdentityError } = useLoginIdentityId(token);
  const { data: statementsList, error } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.statements?.status ?? '')
      ? ['/statements', token]
      : null,
    (_: string, token: string) => client.getStatements(token),
  );
  const currInstitutionName = topLevelLoginIdentity?.institution.institution_name;
  const currInstitutionId = topLevelLoginIdentity?.institution.institution_id;

  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);

  const loginIdentityStatus = loginIdentity?.login_identity.status ?? '';
  const statementStatus = loginIdentity?.login_identity.product_status?.statements?.status ?? '';
  const statementStatusDetails = loginIdentity?.login_identity.product_status?.statements?.status_details ?? '';

  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showErrorMessage = () => {
    setSnackOpen(true);
  };

  const handleViewClick = () => {
    setDialog(CurrentDialog.JSON);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getStatusIcon = () => {
    if (isFailure(loginIdentityStatus)) {
      return (
        <IconButton edge="end" aria-label="action" size="large">
          <Error color="secondary" />
        </IconButton>
      );
    }
    if (isSuccessful(loginIdentityStatus)) {
      return (
        <IconButton edge="end" aria-label="action" size="large">
          <CheckCircle color="primary" />
        </IconButton>
      );
    }
    return (
      <IconButton edge="end" aria-label="action" size="large">
        <CircularProgress size={20} />
      </IconButton>
    );
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    if (loginIdentityError !== undefined && isAuthorizationError(loginIdentityError)) {
      history.push('/login');
    }
    if (error !== undefined && isAuthorizationError(error)) {
      history.push('/login');
    }
    if (loginIdentityError) {
      showErrorMessage();
    }
    if (error) {
      showErrorMessage();
    }
  }, [error, loginIdentityError, history]);

  return (
    <>
      <div className={classes.main}>
        <StyledRootContainer>
          <TopBar title="Statements" showBackButton={true} />
          <LinkLogo title="Statements" component={Description} />
          <List>
            <StyledListItem key={0} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={statementsList?.institution?.institution_id}
                  src={
                    statementsList
                      ? `/img/square/${statementsList?.institution?.institution_id}.svg`
                      : `/img/square/${currInstitutionId}.svg`
                  }
                />
              </StyledListItemAvatar>
              <StyledListItemText
                primary={statementsList ? statementsList?.institution?.institution_name : currInstitutionName}
              />
              <ListItemSecondaryAction>
                {getStatusIcon()}
                <IconButton
                  disabled={!isSuccessful(loginIdentityStatus) || (!statementsList && !error)}
                  edge="end"
                  aria-label="action"
                  onClick={handleMenuClick}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
          </List>
          <TableContainer component={Paper}>
            <StyledTable aria-label="Statements details">
              <TableBodyContent
                loading={!statementsList && !error}
                dataStatus={loginIdentityStatus}
                statementStatus={statementStatus}
                statementStatusDetails={statementStatusDetails}
                statementsList={statementsList}
                handleErrorMessage={showErrorMessage}
              />
            </StyledTable>
          </TableContainer>
          <Dialog
            fullWidth
            open={dialog === CurrentDialog.JSON}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            id="json-viewer"
          >
            <DialogContent>
              <JSONViewer json={statementsList} />
            </DialogContent>
          </Dialog>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <StyledMenuItem onClick={() => handleViewClick()}>
              <ListItemIcon>
                <Receipt fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="View statements" />
            </StyledMenuItem>
          </StyledMenu>
        </StyledRootContainer>
      </div>
      <ErrorSnack
        open={snackOpen}
        handleClose={handleSnackClose}
        mutateKeys={['/authorized', '/login-identity', '/statements']}
      />
    </>
  );
}
