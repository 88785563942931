import { ArrowForwardIos } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import { AccountList, AccountsResponse, Error as APIError } from '../../models';
import { CurrentView } from '../../routes/User';
import { accountStatusDetailsLegit, isFailure, isNotSupported } from '../../routes/Utilities';
import { isAuthorizationError } from '../../services/api';
import { isAPIError } from '../../services/typeGuards';
import { StyledListItem } from '../styledComponents';
import { ProductNotSupported } from '../TableBody';
import getLocalAccounts from '../util/getAccountHelper';
import AccountListItem from './AccountListItem';

interface Props {
  accounts: AccountsResponse | APIError | undefined;
  handleViewClick: (view: CurrentView) => void;
  dataStatus: string;
  dataStatusDetails: string;
  isLoading: boolean;
  className?: string;
}

const AccountsList = ({ accounts, dataStatus, dataStatusDetails, isLoading, handleViewClick }: Props): JSX.Element => {
  const accountList: AccountList[] = [];
  const history = useHistory();

  const viewTransactionsForAccountId = async (accountId: string) => {
    try {
      history.push(`/transaction/${accountId}`);
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        handleViewClick(CurrentView.TRANSACTIONS);
      }
    }
  };

  // to store the list of accounts that has already been processed
  const processedAccounts = new Map<string, boolean>();

  if (isAPIError(accounts)) {
    return <ErrorListItem error={accounts} />;
  }
  if (isFailure(dataStatus)) {
    return (
      <StyledListItem component={Paper}>
        <Typography> Error fetching Accounts </Typography>
      </StyledListItem>
    );
  }

  if (isNotSupported(dataStatus)) {
    return <ProductNotSupported product="Accounts" />;
  }

  if (accountStatusDetailsLegit(dataStatusDetails) === false || isLoading) {
    return (
      <StyledListItem component={Paper}>
        <Typography> Fetching Accounts. Please wait... </Typography>
      </StyledListItem>
    );
  }

  if (accounts === undefined) {
    return (
      <StyledListItem component={Paper}>
        <Typography> No accounts data to show </Typography>
      </StyledListItem>
    );
  }

  const localAccounts = getLocalAccounts(accounts);

  // Go through each account and then
  localAccounts.forEach((accItem) => {
    const accList = accItem as AccountList;
    // if the account is a parent account
    // go through the list of accounts to find the children account
    if (accItem.is_parent) {
      accList.children = localAccounts.filter((a) => {
        // Check that it is not a parent, but shares the same groupId
        if (!a.is_parent && a.group_id === accItem.group_id) {
          processedAccounts.set(a.account_id, true); // Mark the child as processed
          return true;
        }
      });

      processedAccounts.set(accList.account_id, true); // Mark the parent as processed
      accountList.push(accList);
    } else if (!processedAccounts.get(accItem.account_id)) {
      // Not a parent account, and not a child account either. Add straight to accountList
      // Note: We know it is not a child account, since we sort the parents first initially
      // so when we process them, their children would be marked in `processedAccounts`
      processedAccounts.set(accList.account_id, true); // Mark the standalone as processed
      accountList.push(accList);
    }
  });

  return (
    <Paper
      sx={{
        marginBottom: '1rem',
      }}
    >
      {accountList.map((account, index) => (
        <AccountListItem key={index} account={account} index={index} viewTransactions={viewTransactionsForAccountId} />
      ))}
    </Paper>
  );
};

const ErrorListItem = ({ error }: { error: APIError }): JSX.Element => {
  return (
    <StyledListItem component={Paper}>
      <Typography variant="subtitle1">
        <ArrowForwardIos fontSize="small" />
        {error.message}
        <ArrowForwardIos fontSize="small" />
      </Typography>
    </StyledListItem>
  );
};

export default AccountsList;
