import { SvgIcon, Typography, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  title: string;
  subTitle?: string;
  component?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
};

const useStyles = makeStyles(() => ({
  header: {
    minHeight: '20vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  iconContainer: {
    height: '15vh',
    display: 'flex',
    textAlign: 'center',
    justifyItems: 'center',
    alignItems: 'center',
  },
  innerIconContainer: {
    height: '10vh',
    width: '10vh',
    background: '#009FFF',
    borderRadius: '15vh',
  },
  logoContainer: {
    minHeight: '80px',
    height: '10vh',
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    width: '300px',
    height: '100%',
  },
  mainIcon: {
    marginTop: '1.5vh',
    fontSize: '7vh',
    color: '#FFFFFF',
  },
}));

export default function LinkLogo({ title, subTitle, component }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      {component && (
        <div className={classes.iconContainer}>
          <div className={classes.innerIconContainer}>
            <SvgIcon className={classes.mainIcon} component={component}></SvgIcon>
          </div>
        </div>
      )}
      {!component && (
        <div className={classes.logoContainer}>
          <img className={classes.image} src={'/img/logo.svg'} />
        </div>
      )}
      <div>
        <Typography variant="h6" component="h6" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1">{subTitle}</Typography>
      </div>
    </div>
  );
}
