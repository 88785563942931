import { Search } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody as MUITableBody,
  TablePagination,
  IconButton,
} from '@mui/material';

import { TransactionsResponse } from '../../models/index';
import { isFailure, transactionsStatusDetailsLegit, isNotSupported } from '../../routes/Utilities';
import { commonStyle } from '../commonStyle';
import { StyledTable, StyledTableCell } from '../styledComponents';
import { EmptyTableBody, ErrorTableBody, FetchingTableBody, ProductNotSupported } from '../TableBody';

const MAX_ROW_PER_PAGE = 10; // The maximum number of rows in one signal table view

type Props = {
  txnStatus: string;
  txnStatusDetails: string;
  isLoading: boolean;
  transactions?: TransactionsResponse['transactions'];
  onSearchIconClick: (transactionId?: string) => void;

  // Pagination
  totalTransaction?: TransactionsResponse['total_transactions'];
  currentPageNumber: number;
  paginationOnChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
};

type TableBodyProps = Props & {
  canPaginate: boolean;
};

function TableBody({
  txnStatus,
  txnStatusDetails,
  isLoading,
  transactions,
  onSearchIconClick,
  canPaginate,
  totalTransaction,
  currentPageNumber,
  paginationOnChange,
}: TableBodyProps) {
  const product = 'transactions';
  const classes = commonStyle();

  const formatTransaction = (value: number): string => {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  if (isFailure(txnStatus)) {
    return <ErrorTableBody product={product} />;
  } else if (isNotSupported(txnStatus)) {
    return <ProductNotSupported product={product} />;
  } else if (transactionsStatusDetailsLegit(txnStatusDetails) === false || isLoading) {
    return <FetchingTableBody product={product} />;
  } else if (transactions === undefined || transactions.length === 0) {
    return <EmptyTableBody product={product} />;
  } else {
    return (
      <MUITableBody>
        {transactions?.map((transaction) => {
          return (
            <TableRow key={transaction.transaction_id}>
              <TableCell>
                {transaction.description}
                <IconButton
                  size="small"
                  edge="end"
                  aria-label="action"
                  onClick={() => onSearchIconClick(transaction.transaction_id)}
                >
                  <Search />
                </IconButton>
                <Typography
                  className={classes.postDate}
                  component="span"
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  Posted Date: {transaction.posted_date}
                </Typography>
                {transaction.transaction_date !== undefined && (
                  <Typography
                    className={classes.postDate}
                    component="span"
                    variant="caption"
                    display="block"
                    gutterBottom
                  >
                    Transaction Date: {transaction.transaction_date}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                {formatTransaction(transaction.amount?.value)} {transaction.amount?.currency}
              </TableCell>
            </TableRow>
          );
        })}
        {canPaginate && (
          <TableRow>
            <TablePagination
              page={currentPageNumber}
              onPageChange={paginationOnChange}
              count={totalTransaction ?? 0}
              rowsPerPage={MAX_ROW_PER_PAGE}
              rowsPerPageOptions={[]}
              labelDisplayedRows={() => `Page ${currentPageNumber + 1}`}
            />
          </TableRow>
        )}
      </MUITableBody>
    );
  }
}

export default function TransactionsTable(props: Props): JSX.Element {
  const classes = commonStyle();

  // First check if transactions failed
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="Transaction details">
        <TableHead>
          <TableRow>
            <StyledTableCell>Description</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          {...props}
          canPaginate={(props.transactions && props.transactions.length >= 1 && !props.isLoading) ?? false}
        />
      </StyledTable>
    </TableContainer>
  );
}
