import { Box } from '@mui/material';

type Props = {
  isVisible: boolean;
  index: number;
  id?: string;
  ariaLabel?: string;
  children: JSX.Element;
};

export default function TabPanel({ isVisible, index, id, ariaLabel, children }: Props): JSX.Element {
  return (
    <Box
      role="tabpanel"
      hidden={!isVisible}
      id={id ?? `tab-panel-${index}`}
      aria-labelledby={ariaLabel ?? `tab-panel-${index}}`}
    >
      {isVisible && children}
    </Box>
  );
}
