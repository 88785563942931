import { useCallback, useState } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  name: string;
  // Although we are only using string[], Keeping the option open if in the future we want to display more
  // features
  data: string[][];

  open?: boolean;
};

export const useStyles = makeStyles(() => ({
  listItem: {
    backgroundColor: 'white',
    height: '56px',
  },
  bold: {
    fontWeight: 800,
  },
}));

const StyledTableRow = styled(TableRow)({
  height: '56px',
});

const HeadTableCell = styled(TableCell)({
  fontWeight: 800,
});

export default function TableNestedListItem({ name, data, open = true }: Props) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(open);
  const headData = data.slice(0, 1);
  const bodyData = data.slice(1);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const displayData = useCallback(
    (data: string[][], isTableHead = false) => (
      <>
        {data.map((row, i) => (
          <StyledTableRow key={i}>
            {row.map((label, i) => {
              if (isTableHead) return <HeadTableCell>{label}</HeadTableCell>;
              return <TableCell key={i}> {label}</TableCell>;
            })}
          </StyledTableRow>
        ))}
      </>
    ),
    [],
  );

  return (
    <>
      <ListItem button component={Paper} onClick={handleClick} className={classes.listItem}>
        <Typography variant="subtitle1" className={classes.bold}>
          {name}
        </Typography>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table aria-label="Identity Details">
            <TableHead>{displayData(headData, true)}</TableHead>
            <TableBody>{displayData(bodyData)}</TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}
