import { Receipt } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';

import { StyledMenu, StyledMenuItem } from '../StyledMenu';
import { CurrentDialog } from './JSONViewerDialog';

type Props = {
  accountNumberViewable: boolean;
  balanceHistoryViewable: boolean;

  anchorElement: HTMLElement | null;

  onViewClick: (view: CurrentDialog) => void;
  onClose: () => void;
};

export default function DropDownMenu({
  anchorElement,
  accountNumberViewable,
  balanceHistoryViewable,
  onViewClick: handleViewClick,
  onClose: handleClose,
}: Props): JSX.Element {
  return (
    <StyledMenu id="customized-menu" anchorEl={anchorElement} keepMounted open={!!anchorElement} onClose={handleClose}>
      <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.TXN_JSON)}>
        <ListItemIcon>
          <Receipt fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="View Transactions (raw JSON)" />
      </StyledMenuItem>
      {accountNumberViewable && (
        <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.ACC_NUM_JSON)}>
          <ListItemIcon>
            <Receipt fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Account Number (raw JSON)" />
        </StyledMenuItem>
      )}
      {balanceHistoryViewable && (
        <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.BAL_HIST_JSON)}>
          <ListItemIcon>
            <Receipt fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Balance History (raw JSON)" />
        </StyledMenuItem>
      )}
    </StyledMenu>
  );
}
