import { useCallback, useState } from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ErrorSnack from '../components/ErrorSnack';
import LinkLogo from '../components/LinkLogo';
import LinkModal from '../components/LinkModal';
import { RootContainer } from '../components/RootContainer';
import { makeFvLink } from '../components/util/fvlink';
import { PaymentInstruction } from '../models';
import { getApiClient, isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';

const startDate = new Date();
const endDate = new Date();
endDate.setMonth(endDate.getMonth() + 3);

const PaymentInstructionDetails = [
  { header: 'User ID', key: 'userId', value: uuidv4() },

  { header: 'Payment Type', key: 'type', value: 'DEBIT_AUTHORIZATION' },

  { header: 'Amount', key: 'amount', value: '500' },

  { header: 'Currency', key: 'currency', value: 'HKD' },

  { header: 'Start Date', key: 'startDate', value: startDate.toISOString().split('T')[0] },

  { header: 'End Date', key: 'endDate', value: endDate.toISOString().split('T')[0] },

  { header: 'Frequency', key: 'frequency', value: 'MONTHLY' },

  { header: 'Recipient Account ID', key: 'recipientAccountId', value: '1234527894' },

  { header: 'Recipient Name', key: 'recipientName', value: 'Merchant Inc.' },

  { header: 'Sender Account ID', key: 'senderAccountId', value: '3756183957' },

  { header: 'Sender Name', key: 'senderName', value: 'Albert Einstein' },

  { header: 'Remarks', key: 'remarks', value: 'The data used for this payment instruction does not reflect real data' },
];

export default function CreatePaymentInstruction(): JSX.Element {
  const [iframe, setIframe] = useState('');
  const [linkOpen, setLinkOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [paymentInstructionId, setPaymentInstructionId] = useState('');
  const history = useHistory();

  const receiveMessage = (message: any) => {
    if (message.data === 'close') {
      setLinkOpen(false);
    }
    if (message.data === 'success') {
      setLinkOpen(false);
      // Need to check for a valid paymentInstructionId
      // If we don't a call will be made when the paymentInstructionId is the default value
      paymentInstructionId.length > 0 && history.push(`/payment/${paymentInstructionId}`);
    }
  };
  window.addEventListener('message', receiveMessage, false);

  const openLink = async (paymentInstructionId: string) => {
    setSnackOpen(false);
    const userToken = getValue('userToken');
    const linkURI = await getApiClient().getLink(String(userToken), { paymentInstructionId, linkMode: 'test' });
    setIframe(makeFvLink(linkURI));
    setLinkOpen(true);
  };

  const handleClick = async () => {
    try {
      const paymentInstruction = PaymentInstructionDetails.reduce((acc, cur) => ({ ...acc, [cur.key]: cur.value }), {});
      const { payment_instruction_id: paymentInstructionId } = await getApiClient().createPaymentInstruction(
        String(getValue('userToken')),
        paymentInstruction as unknown as PaymentInstruction,
      );
      setPaymentInstructionId(paymentInstructionId);
      await openLink(paymentInstructionId);
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        setSnackOpen(true);
      }
    }
  };

  const handleClose = useCallback(() => {
    setLinkOpen(false);
    setSnackOpen(false);
  }, []);

  const handleErrorRetry = useCallback(() => {
    // if payment instruction id exists open link
    paymentInstructionId && openLink(paymentInstructionId);
  }, [paymentInstructionId]);

  return (
    <RootContainer>
      <LinkLogo title="Finverse Demo App" subTitle="Setup Payment Instruction" />
      <Table aria-label="a table with details of the payment instruction to be setup" sx={{ marginBottom: 4 }}>
        <TableBody>
          {PaymentInstructionDetails.map(({ header, value }) => (
            <TableRow key={header}>
              <TableCell component="th" scope="row">
                {header}
              </TableCell>
              <TableCell>{value.replaceAll('_', ' ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="contained" onClick={handleClick}>
        Setup Payment Instruction
      </Button>
      <LinkModal url={iframe} linkOpen={linkOpen} handleClose={handleClose} />
      <ErrorSnack open={snackOpen} handleClose={handleClose} retry={handleErrorRetry} />
    </RootContainer>
  );
}
