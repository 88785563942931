import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)(() => ({
  height: '56px',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #CFD1DC',
  '& img': {
    objectFit: 'contain',
  },
  '& svg': {
    verticalAlign: 'text-bottom',
  },
})) as unknown as typeof ListItem;

export default StyledListItem;
