type AppConfig = {
  env: string;
  apiHost: string;
};

function getConfig(): AppConfig {
  const host = window.location.hostname;
  // const host = ''
  const env = getEnv(host);

  if (env === 'stg3') {
    return {
      env: 'STG3',
      apiHost: 'https://demo-api.stg3.finverse.net',
    };
  }

  if (env === 'stg4') {
    return {
      env: 'STG4',
      apiHost: 'https://demo-api.stg4.finverse.net',
    };
  }

  if (env === 'dev2') {
    return {
      env: 'DEV2',
      apiHost: 'https://demo-api.dev2.finverse.net',
    };
  }

  if (env === 'sand') {
    return {
      env: 'SAND',
      apiHost: 'https://demo-api.sandbox.finverse.net',
    };
  }

  if (env === 'prod') {
    return {
      env: 'PROD',
      apiHost: 'https://demo-api.prod.finverse.net',
    };
  }

  return {
    env: 'LOCAL',
    apiHost: 'https://demo-api.dev2.finverse.net',
  };
}

function getEnv(url: string): string {
  // the following should be okay as demo-api.dev.finverse.net will return dev before it does demo
  if (url.includes('local')) return 'local';
  if (url.includes('dev2')) return 'dev2';
  if (url.includes('stg3')) return 'stg3';
  if (url.includes('stg4')) return 'stg4';
  if (url.includes('prod')) return 'prod';
  if (url.includes('sand')) return 'sand';
  return 'production';
}

export default getConfig();
