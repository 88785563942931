import { Paper, TableContainer, TableHead, TableRow, TableCell, TableBody as MUITableBody } from '@mui/material';

import { IncomeEstimate, MonthlyIncomeEstimate } from '../../models/index';
import { isFailure, incomeStatusDetailsLegit, isNotSupported } from '../../routes/Utilities';
import { commonStyle } from '../commonStyle';
import { StyledTable, StyledTableCell } from '../styledComponents';
import { EmptyTableBody, ErrorTableBody, FetchingTableBody, ProductNotSupported } from '../TableBody';

type Props = {
  incomeStatus: string;
  incomeStatusDetails: string;
  isLoading: boolean;
  incomeEstimate?: IncomeEstimate;
  monthlyIncome?: MonthlyIncomeEstimate[];
};

type TableBodyProps = Props & {
  canPaginate: boolean;
};

function TableBody({ incomeStatus, incomeStatusDetails, isLoading, incomeEstimate, monthlyIncome }: TableBodyProps) {
  const product = 'Income Estimation';

  const formatAmount = (value: number): string => {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  if (isFailure(incomeStatus)) {
    return <ErrorTableBody product={product} colSpan={3} />;
  } else if (isNotSupported(incomeStatus)) {
    return <ProductNotSupported product={product} colSpan={3} />;
  } else if (incomeStatusDetailsLegit(incomeStatusDetails) === false || isLoading) {
    return <FetchingTableBody product={product} colSpan={3} />;
  } else if (!incomeEstimate || !monthlyIncome || monthlyIncome.length <= 0) {
    return <EmptyTableBody product={product} colSpan={3} />;
  } else {
    return (
      <MUITableBody>
        {monthlyIncome?.map((monthly) => {
          let month = '';
          if (monthly.month && monthly.month >= 1 && monthly.month <= 12) {
            const newDate = new Date();
            newDate.setMonth(monthly.month);
            month = newDate.toLocaleString('en-US', {
              month: 'short',
            });
          }
          return (
            <TableRow key={`${monthly.year} ${monthly.month}`}>
              <TableCell sx={{ width: '33%' }}>{monthly.year}</TableCell>
              <TableCell sx={{ width: '33%' }}>{month}</TableCell>
              <TableCell sx={{ width: '34%' }}>
                {formatAmount(monthly.estimated_income?.amount ?? 0)} {monthly.estimated_income?.currency}
              </TableCell>
            </TableRow>
          );
        })}
      </MUITableBody>
    );
  }
}

export default function IncomeEstimationTable(props: Props): JSX.Element {
  const classes = commonStyle();
  // First check if transactions failed
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="Income Total details">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ width: '33%' }}>Year</StyledTableCell>
            <StyledTableCell sx={{ width: '33%' }}>Month</StyledTableCell>
            <StyledTableCell sx={{ width: '34%' }}>{`Amount(${
              props.incomeEstimate ? props.incomeEstimate?.currency : 'Currency'
            })`}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody {...props} canPaginate={false} />
      </StyledTable>
    </TableContainer>
  );
}
