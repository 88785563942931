import { Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import {
  TransactionsResponse,
  AccountsResponse,
  LoginIdentityResponse,
  LoginIdentityHistoryResponse,
  IdentityResponse,
  Error as APIError,
  StatementsResponse,
  IncomeResponse,
} from '../../models/index';
import { CurrentDialog, CurrentView } from '../../routes/User';
import { getApiClient, getValue } from '../../services';
import JSONViewer from '../JSONViewer';

const useStyles = makeStyles((theme) => ({
  unlinkContentText: {
    margin: theme.spacing(2),
  },
}));

interface JsonViewableResponse {
  accounts: AccountsResponse | APIError | undefined;
  transactions: TransactionsResponse | APIError | undefined;
  loginIdentity: LoginIdentityResponse | APIError | undefined;
  loginIdentityHistory: LoginIdentityHistoryResponse | APIError | undefined;
  statementsList: StatementsResponse | APIError | undefined;
  identity: IdentityResponse | APIError | undefined;
  income: IncomeResponse | APIError | undefined;
}

interface Props {
  dialog: CurrentDialog;
  view: CurrentView;
  jsonViewableResponse: JsonViewableResponse;
  handleClose: () => void;
}

const UnlinkDialog = ({ dialog, handleClose, view, jsonViewableResponse }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { accounts, transactions, loginIdentityHistory, statementsList, identity, loginIdentity, income } =
    jsonViewableResponse;

  const confirmUnlink = async () => {
    try {
      const token = String(getValue('userToken'));
      await getApiClient().unlink(token);
      history.push('/home');
    } catch (err) {
      history.push('/login');
    }
  };

  const getJson = ():
    | AccountsResponse
    | TransactionsResponse
    | LoginIdentityResponse
    | LoginIdentityHistoryResponse
    | APIError
    | IdentityResponse
    | IncomeResponse
    | undefined => {
    if (view === CurrentView.ACCOUNTS) return accounts;
    if (view === CurrentView.TRANSACTIONS) return transactions;
    if (view === CurrentView.LOGIN_IDENTITY_HISTORY) return loginIdentityHistory;
    if (view === CurrentView.STATEMENTS) return statementsList;
    if (view === CurrentView.IDENTITY) return identity;
    if (view === CurrentView.INCOME) return income;
    return loginIdentity;
  };

  return (
    <>
      <Dialog
        open={dialog === CurrentDialog.UNLINK_CONFIRM}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unlink?</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.unlinkContentText} id="alert-dialog-description">
            Are you sure you want to unlink your connected accounts?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={confirmUnlink} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={dialog === CurrentDialog.JSON}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        id="json-viewer"
      >
        <DialogContent>
          <JSONViewer json={getJson()} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UnlinkDialog;
