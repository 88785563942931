import { ArrowForwardIos } from '@mui/icons-material';
import { List, ListItem, ListItemText, Typography, Collapse } from '@mui/material';

import { AccountList } from '../../models/index';
import { commonStyle } from '../commonStyle';
import { StyledListItem } from '../styledComponents';

interface Props {
  index: number;
  account: AccountList;
  viewTransactions: (accountId: string) => Promise<void>;
}

const AccountListItem = (props: Props): JSX.Element => {
  const { index, account, viewTransactions: viewTransactionsForAccountId } = props;
  const classes = commonStyle();

  return (
    <>
      <StyledListItem key={index} button component="a" onClick={() => viewTransactionsForAccountId(account.account_id)}>
        <ListItemText>
          <Typography variant="subtitle1">
            {account.account_name} <ArrowForwardIos fontSize="small" />
          </Typography>{' '}
        </ListItemText>
        <ListItemText style={{ textAlign: 'right' }}>
          <Typography variant="body1">
            {account.balance.value ? account.balance.value.toLocaleString() : Number(0).toLocaleString()}{' '}
            {account.balance.currency}
          </Typography>
        </ListItemText>
      </StyledListItem>
      <Collapse in timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {account.children?.map((child, index) => {
            return (
              <ListItem
                className={classes.nestedListItem}
                key={index}
                button
                component="a"
                onClick={() => viewTransactionsForAccountId(child.account_id)}
              >
                <ListItemText>
                  <Typography variant="subtitle1">
                    {child.account_name} <ArrowForwardIos fontSize="small" />
                  </Typography>{' '}
                </ListItemText>
                <ListItemText style={{ textAlign: 'right' }}>
                  <Typography variant="body1">
                    {child.balance.value ? child.balance.value.toLocaleString() : Number(0).toLocaleString()}{' '}
                    {child.balance.currency}
                  </Typography>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

export default AccountListItem;
