import { useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { Avatar, Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';

type AppProps = {
  open: boolean;
  handleClose: () => void;
  handleCloseCreateModal: () => void;
  errorTitle: string;
  errorMessage: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
  },
  containerDesktop: {
    paddingTop: '2%',
    paddingBottom: '2%',
  },
  linkBase: {
    margin: 'auto',
    backgroundColor: 'white',
  },
  linkDesktop: {
    overflow: 'hidden',
    minHeight: '720px', // 16:9 ratio
    height: '100%',
    maxHeight: '720px',
    width: '405px',
    minWidth: '405px',
    borderRadius: '8px',
    boxShadow: theme.shadows[5],
  },
  linkMobile: {
    height: '100%',
    width: '100%',
  },
  logo: {
    height: '80px',
    width: '80px',
    margin: 'auto',
  },
}));

export default function CreatePaymentErrorModal({
  open,
  handleClose,
  handleCloseCreateModal,
  errorTitle,
  errorMessage,
}: AppProps): JSX.Element {
  const isMobile = useCallback((): boolean => {
    return (
      window.matchMedia('(max-width: 760px)').matches &&
      /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
  }, []);
  const isPortrait = window.innerWidth > window.innerHeight ? false : true;
  const classes = useStyles();

  const containerStyle = `${classes.container} ${isMobile() && isPortrait ? '' : classes.containerDesktop}`;
  const linkStyle = `${classes.linkBase} ${isMobile() && isPortrait ? classes.linkMobile : classes.linkDesktop}`;

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          handleCloseCreateModal();
        }}
        closeAfterTransition
        hideBackdrop
      >
        <Fade in={open}>
          <Box className={containerStyle}>
            <Box className={linkStyle}>
              <Box style={{ width: '100%', textAlign: 'right' }}>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    handleClose();
                    handleCloseCreateModal();
                  }}
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Avatar className={classes.logo} src="/img/institutions/default.svg" />
              <h1 style={{ width: '100%', textAlign: 'center' }}>Error</h1>

              <Box style={{ width: '100%', textAlign: 'center' }}>
                <ErrorIcon className={classes.logo} color="primary" />
              </Box>

              <h2 style={{ textAlign: 'center', margin: '20px' }}>{errorTitle}</h2>
              <p style={{ textAlign: 'center', margin: '20px' }}> {errorMessage}</p>

              <Box style={{ width: '100%', textAlign: 'center' }}>
                <Button
                  style={{ width: '80%', margin: 'auto' }}
                  fullWidth={true}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                    handleCloseCreateModal();
                  }}
                >
                  Exit
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
