import { CheckCircle, Error } from '@mui/icons-material';
import { IconButton, CircularProgress } from '@mui/material';

import { isSuccessful, isFailure } from '../routes/Utilities';

interface Props {
  status: string | undefined;
  isSuccessfulOnClick?: () => void;
  isFailureOnClick?: () => void;
  isLoadingOnClick?: () => void;
}

const StatusIcon = ({ status, isSuccessfulOnClick, isFailureOnClick, isLoadingOnClick }: Props): JSX.Element => {
  if (isFailure(status || '')) {
    return (
      <IconButton edge="end" aria-label="action" onClick={isFailureOnClick} size="large">
        <Error color="secondary" />
      </IconButton>
    );
  }
  if (isSuccessful(status || '')) {
    return (
      <IconButton edge="end" aria-label="action" onClick={isSuccessfulOnClick} size="large">
        <CheckCircle color="primary" />
      </IconButton>
    );
  }
  return (
    <IconButton edge="end" aria-label="action" onClick={isLoadingOnClick} size="large">
      <CircularProgress size={20} />
    </IconButton>
  );
};

export default StatusIcon;
