import { useEffect, useMemo, useState } from 'react';

import { Payment, Receipt, MoreVert } from '@mui/icons-material';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';

import ErrorSnack from '../components/ErrorSnack';
import JSONViewerDialogCommon from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import CreatePaymentModal from '../components/payment/CreatePaymentModal';
import { RootContainer } from '../components/RootContainer';
import StatusIcon from '../components/StatusIcon';
import { StyledMenu, StyledMenuItem } from '../components/StyledMenu';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { GetMandateResponse } from '../models/index';
import { isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';
import { isAPIError } from '../services/typeGuards';
import { isSuccessful } from './Utilities';

const ListItemButtonStyle = {
  height: '56px',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #CFD1DC',
  '& img': {
    objectFit: 'contain',
  },
  '& svg': {
    verticalAlign: 'text-bottom',
  },
  width: '100%',
};

const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '15px',
    color: '#201F24',
    fontWeight: '800',
  },
  '& p': {
    fontSize: '14px',
    color: '#A1A6C1',
  },
});

export enum CurrentDialog {
  NONE = 0,
  MANDATE_JSON,
}

export default function MandateDetails(): JSX.Element {
  const history = useHistory();
  const client = useAPIClient();

  const { mandateId } = useParams<{ mandateId: string }>();
  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);

  const {
    data: mandate,
    error: mandateError,
    mutate: mutateMandate,
  } = useSWR<GetMandateResponse>(
    [`/mandate/${mandateId}`, token, mandateId],
    (_: string, token: string, mandateId: string) => client.getMandate(token, mandateId),
  );
  const loading = !mandate && !mandateError;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);
  const [createPaymentVisible, setCreatePaymentVisible] = useState(false);

  const openCreatePayment = () => setCreatePaymentVisible(true);
  const closeCreatePayment = () => setCreatePaymentVisible(false);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const showErrorMessage = () => {
    setSnackOpen(true);
  };
  const handleViewClick = (dialog: CurrentDialog) => {
    setDialog(dialog);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const listItemSecondaryText = useMemo((): string => {
    if (isAPIError(mandate)) return mandate.message;
    if (mandate == null || mandate.mandate_id == null) return 'Fetching payment mandate...';
    return '';
  }, [mandate]);

  const mutate = () => {
    mutateMandate();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errors = [mandateError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [mandateError, history]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RootContainer>
          <LinkLogo title="Payment Mandate Details" component={Payment} />

          <List>
            {isSuccessful(mandate?.status || '') && (
              <Button
                color="primary"
                size="medium"
                variant="contained"
                style={{ float: 'right' }}
                onClick={openCreatePayment}
              >
                Create Payment
              </Button>
            )}

            <ListItemButton
              component="a"
              key="mandate"
              // cannot use styled api here because the types get overridden because of some styled & material ui types
              sx={ListItemButtonStyle}
            >
              <StyledListItemText primary="Mandate Details" secondary={listItemSecondaryText} />
              <ListItemSecondaryAction>
                <StatusIcon status={mandate?.status} />
                <IconButton disabled={loading} edge="end" aria-label="action" onClick={handleMenuClick} size="large">
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Table aria-label="a table with details of the payment mandate" sx={{ marginBottom: 4 }}>
              <TableBody>
                {Object.entries(mandate ?? {}).map(([key, value]) => {
                  if (typeof value !== 'string' && typeof value !== 'number' && !Array.isArray(value)) {
                    return Object.entries(value).map(([subKey, subVal]) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {
                            // Title case
                            `${subKey === 'name' ? `${key[0].toUpperCase() + key.slice(1).toLowerCase()} ` : ''}${subKey
                              .split('_')
                              .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                              .join(' ')}`
                          }
                        </TableCell>
                        <TableCell>{typeof subVal === 'object' ? JSON.stringify(subVal, null, 1) : subVal}</TableCell>
                      </TableRow>
                    ));
                  }
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {
                          // Title case
                          key
                            .split('_')
                            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                        }
                      </TableCell>
                      <TableCell>{typeof value === 'object' ? JSON.stringify(value) : value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </List>
          <JSONViewerDialogCommon open={dialog === CurrentDialog.MANDATE_JSON} json={mandate} onClose={handleClose} />
          <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
            <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.MANDATE_JSON)}>
              <ListItemIcon>
                <Receipt fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="View Mandate Details (raw JSON)" />
            </StyledMenuItem>
          </StyledMenu>
          {createPaymentVisible && (
            <CreatePaymentModal open={createPaymentVisible} handleClose={closeCreatePayment} mandateData={mandate} />
          )}
        </RootContainer>
      </Box>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
