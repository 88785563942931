import { useEffect, useState } from 'react';

import { AccountBalanceWallet, MoreVert, Receipt } from '@mui/icons-material';
import { List, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { commonStyle } from '../components/commonStyle';
import ErrorSnack from '../components/ErrorSnack';
import TableNestedListItem from '../components/Identity/NestedListItem';
import JSONViewerDialog from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import StatusIcon from '../components/StatusIcon';
import { StyledAvatar, StyledListItem, StyledListItemAvatar, StyledListItemText } from '../components/styledComponents';
import { StyledMenuItem, StyledMenu } from '../components/StyledMenu';
import { ProductNotSupported } from '../components/TableBody';
import TopBar from '../components/TopBar';
import useLoginIdentityId from '../hooks/data/useLoginIdentityId';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { DateOfBirth, IdentityResponse, Name, Address, Email, PhoneNumber } from '../models';
import { isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';
import { identityStatusDetailsLegit, isFailure, isNotSupported, isProductEndStatus } from './Utilities';

enum CurrentDialog {
  NONE = 0,
  IDENTITY,
}

export default function Identity(): JSX.Element {
  const history = useHistory();
  const classes = commonStyle();
  const client = useAPIClient();

  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);
  const { data: loginIdentity, error: loginIdentityError } = useLoginIdentityId(token);
  const { data: identity, error } = useSWR(
    isProductEndStatus(loginIdentity?.login_identity.product_status.identity?.status ?? '')
      ? ['/identity', token]
      : null,
    (_: string, token: string) => client.getIdentity(token),
  );

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [errorSnackOpen, setErrorSnackOpen] = useState<boolean>(false);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setDialog(CurrentDialog.NONE);
    setAnchorElement(null);
  };
  const handleErrorSnackOpen = () => {
    setErrorSnackOpen(true);
  };
  const handleErrorSnackClose = () => {
    setErrorSnackOpen(false);
  };
  const handleViewClick = (dialog: CurrentDialog) => {
    setDialog(dialog);
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    if (loginIdentityError !== undefined && isAuthorizationError(loginIdentityError)) {
      history.push('/login');
    }
    if (error !== undefined && isAuthorizationError(error)) {
      history.push('/login');
    }
    if (loginIdentityError) {
      handleErrorSnackOpen();
    }
    if (error) {
      handleErrorSnackOpen();
    }
  }, [error, loginIdentityError, history]);

  return (
    <>
      <div className={classes.main}>
        <div className={classes.listContainer}>
          <TopBar title="Transactions" showBackButton={true} />
          <LinkLogo title="Identity" component={AccountBalanceWallet} />
          <List>
            <StyledListItem key={loginIdentity?.institution?.institution_id} button component="a">
              <StyledListItemAvatar>
                <StyledAvatar
                  alt={loginIdentity?.institution?.institution_id}
                  src={`/img/square/${loginIdentity?.institution?.institution_id}.svg`}
                />
              </StyledListItemAvatar>
              <StyledListItemText primary={loginIdentity?.institution?.institution_name} />
              <ListItemSecondaryAction>
                <StatusIcon status={loginIdentity?.login_identity.status ?? ''} />
                <IconButton edge="end" aria-label="action" onClick={handleMenuClick} size="large">
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </StyledListItem>
            <ListItems
              identity={identity}
              dataStatus={loginIdentity?.login_identity.product_status.identity?.status ?? ''}
              dataStatusDetails={loginIdentity?.login_identity.product_status.identity?.status_details ?? ''}
              isLoading={!identity && !error}
            />
            <JSONViewerDialog open={dialog === CurrentDialog.IDENTITY} json={identity} onClose={handleMenuClose} />
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorElement}
              keepMounted
              open={!!anchorElement}
              onClose={handleMenuClose}
            >
              {
                <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.IDENTITY)}>
                  <ListItemIcon>
                    <Receipt fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="View Identity (raw JSON)" />
                </StyledMenuItem>
              }
            </StyledMenu>
          </List>
        </div>
      </div>
      <ErrorSnack
        open={errorSnackOpen}
        handleClose={handleErrorSnackClose}
        mutateKeys={['/authorized', '/login-identity', '/identity']}
      />
    </>
  );
}

type ListItemProps = {
  dataStatus: string;
  dataStatusDetails: string;
  isLoading: boolean;
  identity?: IdentityResponse;
};

function ListItems({ dataStatus, dataStatusDetails, isLoading, identity }: ListItemProps): JSX.Element {
  const classes = commonStyle();

  const titleCase = (input: string) =>
    input
      .split('_')
      .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');

  const parseValueFromIdentity = (values: Address[] | Email[] | Name[] | PhoneNumber[] | DateOfBirth[]): string[][] => {
    const value = values[0];
    if (Object.prototype.hasOwnProperty.call(value, 'full_name')) {
      return [['full_name']].concat((values as Name[]).map(({ full_name }) => [full_name]));
    }
    if (
      Object.prototype.hasOwnProperty.call(value, 'date_of_birth') ||
      Object.prototype.hasOwnProperty.call(value, 'masked_date_of_birth')
    ) {
      return [['date_of_birth']].concat(
        (values as DateOfBirth[]).map(({ date_of_birth, masked_date_of_birth }) => [
          date_of_birth ?? masked_date_of_birth,
        ]),
      );
    }
    if (
      Object.prototype.hasOwnProperty.call(value, 'email') ||
      Object.prototype.hasOwnProperty.call(value, 'masked_email')
    ) {
      return [['email']].concat((values as Email[]).map(({ email, masked_email }) => [email ?? masked_email]));
    }
    if (
      Object.prototype.hasOwnProperty.call(value, 'mobile_phone') ||
      Object.prototype.hasOwnProperty.call(value, 'masked_phone')
    ) {
      return [['mobile_phone']].concat(
        (values as PhoneNumber[]).map(({ mobile_phone, masked_phone }) => [mobile_phone ?? masked_phone]),
      );
    }
    if (Object.prototype.hasOwnProperty.call(value, 'full_address')) {
      return [['full_address']].concat((values as Address[]).map(({ full_address }) => [full_address ?? '']));
    }
    return [[]];
  };

  if (isFailure(dataStatus)) {
    return <StyledListItem>Error fetching Identity</StyledListItem>;
  }

  if (isNotSupported(dataStatus)) {
    return <ProductNotSupported product="Identity" />;
  }

  if (identityStatusDetailsLegit(dataStatusDetails) === false || isLoading) {
    return <StyledListItem>Fetching Identity. Please wait...</StyledListItem>;
  }

  if (identity === undefined || identity.identity === undefined) {
    <StyledListItem>No Identity data to show</StyledListItem>;
  }

  return (
    <>
      {Object.entries(identity?.identity ?? {}).map(([key, values]) => {
        if (values.length === 0) return null;
        return (
          <div className={classes.productListItem} key={key}>
            <TableNestedListItem key={key} name={titleCase(key)} data={parseValueFromIdentity(values)} />
          </div>
        );
      })}
    </>
  );
}
