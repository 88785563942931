import { useEffect } from 'react';

export function getValue(key: string): string | null {
  return localStorage.getItem(key);
}

export function setValue(key: string, value: string): void {
  return localStorage.setItem(key, value);
}

export function useClearLocalStorage(): void {
  useEffect(() => {
    localStorage.clear();
  }, []);
}
