import { BusinessCenter, Person } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';

import LinkLogo from '../components/LinkLogo';
import { RootContainer } from '../components/RootContainer';
import { isAuthorizationError } from '../services/api';

const ListItemButtonStyle = {
  height: '56px',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #CFD1DC',
  '& img': {
    objectFit: 'contain',
  },
  '& svg': {
    verticalAlign: 'text-bottom',
  },
};

export default function SelectSenderType(): JSX.Element {
  const history = useHistory();
  const handleClick = async (senderType: string) => {
    try {
      history.push(`/mandate?sender_type=${senderType}`);
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      }
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RootContainer>
          <LinkLogo title="Setup Payment Mandate" subTitle="Bank account type" />
          <Grid container spacing={2}>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={4}>
              <Button variant="contained" size="large" endIcon={<Person />} onClick={() => handleClick('INDIVIDUAL')}>
                Individual
              </Button>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                size="large"
                endIcon={<BusinessCenter />}
                onClick={() => handleClick('BUSINESS')}
              >
                Business
              </Button>
            </Grid>
            <Grid item xs={0} md={2} />
          </Grid>
        </RootContainer>
      </Box>
    </>
  );
}
