import { useState, useEffect } from 'react';

import { Box, CardHeader } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import ErrorSnack from '../components/ErrorSnack';
import LinkLogo from '../components/LinkLogo';
import LinkModal from '../components/LinkModal';
import { RootContainer } from '../components/RootContainer';
import { makeFvLink } from '../components/util/fvlink';
import useAPIClient from '../hooks/useClient';
import { getValue, isAuthorizationError, setValue } from '../services';

export default function Home(): JSX.Element {
  const [iframe, setIframe] = useState('');
  const [linkOpen, setLinkOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const history = useHistory();
  const client = useAPIClient();

  const receiveMessage = (message: any) => {
    if (message.data === 'close') {
      setLinkOpen(false);
    }
    if (message.data === 'success') {
      setLinkOpen(false);
      history.push('/user');
    }
  };
  window.addEventListener('message', receiveMessage, false);

  const handleClose = () => {
    setLinkOpen(false);
    setSnackOpen(false);
  };

  const showErrorMessage = () => {
    setSnackOpen(true);
  };

  useEffect(() => {
    async function load() {
      const userToken = getValue('userToken');
      const validToken = await client.checkToken(String(userToken));
      if (!validToken) history.push('/login');
    }
    load();
  });

  const openLink = async () => {
    try {
      setSnackOpen(false);
      const userToken = getValue('userToken');

      const newToken = await client.regenerateUserId(String(userToken));
      setValue('userToken', newToken);

      const linkURI = await client.getLink(String(newToken));
      setIframe(makeFvLink(linkURI));
      setLinkOpen(true);
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        showErrorMessage();
      }
    }
  };

  const openXeroDemo = async () => {
    window.location.replace(client.getXeroDemoUrl());
  };

  const handleSetupPaymentMandate = () => {
    try {
      history.push('/select-sender-type');
    } catch (err) {
      if (isAuthorizationError(err)) {
        history.push('/login');
      } else {
        showErrorMessage();
      }
    }
  };

  return (
    <RootContainer maxWidth="xl">
      <LinkLogo title="Finverse Demo App" subTitle="Select an API to demo"></LinkLogo>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <FlowCard
          title="Payment Links API"
          buttonLabel="Try Payment Link in Xero"
          description="Create a payment link and use it to make a payment"
          handleClick={openXeroDemo}
        />

        <FlowCard
          title="Payments API - Mandate"
          buttonLabel="Setup Payment Mandate"
          description="Create a payment mandate to pre-authorize future direct debits"
          handleClick={handleSetupPaymentMandate}
        />

        <FlowCard
          title="Data API"
          buttonLabel="Link a Bank Account"
          description="Link an account and access real-time financial data"
          handleClick={openLink}
        />
      </Box>
      <LinkModal url={iframe} linkOpen={linkOpen} handleClose={handleClose} />
      <ErrorSnack open={snackOpen} handleClose={handleClose} retry={openLink} />
    </RootContainer>
  );
}

const cardElementsStyle = {
  minHeight: '30%',
};

function FlowCard({
  title,
  description,
  buttonLabel = 'Submit',
  handleClick,
}: {
  title: string;
  description?: string;
  buttonLabel?: string;
  handleClick?: () => void;
}): JSX.Element {
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: '650px',
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        outline: 'solid 1px #e0e0e0',
      }}
      elevation={0}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          sx: {
            fontWeight: 'semi-bold',
          },
        }}
        sx={cardElementsStyle}
      ></CardHeader>
      <CardContent sx={cardElementsStyle}>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions sx={{ placeContent: 'center', ...cardElementsStyle }}>
        <Button variant="contained" onClick={handleClick} disabled={!handleClick}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
}
