import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { blue, red } from '@mui/material/colors';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import App from './App';
import reportWebVitals from './reportWebVitals';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/msw/browser');
  worker.start();
}

const theme = createTheme({
  palette: {
    primary: { main: blue[500] },
    secondary: { main: red[500] },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
