import { useCallback, SyntheticEvent } from 'react';

import { Snackbar, Button, SnackbarCloseReason } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSWRConfig } from 'swr';

type Props = {
  handleClose: (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
  open: boolean;
  retry?: () => void;
  mutateKeys?: string | string[];
  autoHideDuration?: number;
};

const useStyles = makeStyles(() => ({
  errorMsg: {
    '& div': {
      backgroundColor: '#FFF',
      color: '#2196f3',
    },
  },
}));

export default function ErrorSnack({
  handleClose,
  retry,
  open,
  mutateKeys,
  autoHideDuration = 5000,
}: Props): JSX.Element {
  const classes = useStyles();
  const { mutate } = useSWRConfig();

  const handleClick = useCallback(() => {
    if (retry !== undefined) {
      retry();
      return;
    }
    if (mutateKeys !== undefined) {
      if (typeof mutateKeys === 'string') {
        mutate(mutateKeys);
        return;
      }
      mutateKeys.forEach((key) => mutate(key));
    }
  }, [retry, mutateKeys, mutate]);

  return (
    <Snackbar
      className={classes.errorMsg}
      key="error-message"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message="There was an error"
      action={
        <Button color="primary" size="medium" onClick={handleClick}>
          Click here to try again
        </Button>
      }
    />
  );
}
