// THIS COMPONENT IS REQUIRED
// This component is for getting the token from developer portal so login is not required

import { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import useAPIClient from '../hooks/useClient';
import { setValue } from '../services';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function GetToken(): JSX.Element {
  const history = useHistory();
  const query = useQuery();
  const client = useAPIClient();

  useEffect(() => {
    async function load() {
      // Get the Auth0 token
      const queryToken = query.get('access_token');
      if (queryToken !== null) {
        const token = queryToken;

        // Now use Auth0 token to call Demo-API
        // and exchange for regenerated token
        const newToken = await client.regenToken(token);

        setValue('userToken', newToken);
        history.push('/home');
      } else {
        history.push('/login');
      }
    }
    load();
  }, [location]);

  return <></>;
}
