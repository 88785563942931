import { LinkOff, Receipt, AccountBalance, Sync, Link, History, Fingerprint } from '@mui/icons-material';
import { ListItemText, ListItemIcon, MenuItem } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import withStyles from '@mui/styles/withStyles';

import { CurrentView } from '../../routes/User';

const StyledMenuBaseline = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

interface Props {
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
  handleViewClick: (view: CurrentView) => void;
  sync: () => Promise<void>;
  showUnlinkConfirm: () => void;
}

const StyledMenu = ({ anchorEl, handleMenuClose, handleViewClick, showUnlinkConfirm, sync }: Props): JSX.Element => (
  <StyledMenuBaseline
    id="customized-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleMenuClose}
  >
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.LOGIN_IDENTITY_HISTORY)}>
      <ListItemIcon>
        <History fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View status history" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.LOGIN_IDENTITY)}>
      <ListItemIcon>
        <Link fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View status" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.IDENTITY)}>
      <ListItemIcon>
        <Fingerprint fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View identity" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.ACCOUNTS)}>
      <ListItemIcon>
        <AccountBalance fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View accounts" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.TRANSACTIONS)}>
      <ListItemIcon>
        <Receipt fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View transactions" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.STATEMENTS)}>
      <ListItemIcon>
        <Receipt fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View statements" />
    </StyledMenuItem>
    <StyledMenuItem onClick={() => handleViewClick(CurrentView.INCOME)}>
      <ListItemIcon>
        <Receipt fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="View income estimation" />
    </StyledMenuItem>
    <StyledMenuItem onClick={sync}>
      <ListItemIcon>
        <Sync fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Sync data" />
    </StyledMenuItem>
    <StyledMenuItem onClick={showUnlinkConfirm}>
      <ListItemIcon>
        <LinkOff fontSize="small" />
      </ListItemIcon>
      <ListItemText primary="Unlink" />
    </StyledMenuItem>
  </StyledMenuBaseline>
);

export default StyledMenu;
