import axios, { AxiosError, AxiosResponse } from 'axios';

import config from '../config';
import {
  CreateMandate,
  CreatePaymentRequest,
  LoginIdentityResponse,
  PaymentInstruction,
  PaymentResponse,
} from '../models';

export const contentType = {
  json: 'application/json',
  form: 'application/x-www-form-urlencoded',
};

type GenerateLinkOptions = {
  linkMode?: string;
  paymentInstructionId?: string;
  productsRequested?: string[];
};

type GenerateMandateLink = {
  mandate_id: string;
  link_customizations?: mandateLinkCustomizations;
};

type mandateLinkCustomizations = {
  products_supported?: string[];
  countries?: string[];
  institution_id?: string;
  institution_status?: string;
  language?: string;
  link_mode?: string;
  ui_mode?: string;
  user_type?: string;
};

export function getApiClient(apiHost?: string): APIClient {
  return new APIClient(apiHost);
}

export function isAuthorizationError(err: Error): boolean {
  return err.message.includes('status code 403') || err.message.includes('status code 401');
}

export class APIClient {
  private apiHost: string;
  constructor(apiHost?: string) {
    this.apiHost = apiHost ?? config.apiHost;
  }

  // POST /login
  async login(username: string, password: string): Promise<string> {
    const url = `${this.apiHost}/login`;
    const requestBody = { username, password };
    const resp = await this.postData(url, { 'content-type': contentType.json }, requestBody);
    return resp.data.accessToken;
  }

  async regenToken(token: string): Promise<string> {
    const url = `${this.apiHost}/regen`;
    const resp = await this.getData(url, {
      'content-type': contentType.json,
      Authorization: `Bearer: ${token}`,
    });

    return resp.data.accessToken;
  }

  // GET /authorized
  async checkToken(token: string): Promise<boolean> {
    try {
      const url = `${this.apiHost}/authorized`;
      await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      return true;
    } catch (err) {
      return !isAuthorizationError(err as Error);
    }
  }

  async getLoginIdentity(token: string): Promise<LoginIdentityResponse> {
    const url = `${this.apiHost}/login-identity`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    if (Array.isArray(resp.data) && resp.data.length > 0) {
      return resp.data.slice(-1)[0];
    }
    return resp.data;
  }

  async getLoginIdentityHistory(token: string, liid: string): Promise<any> {
    const url = `${this.apiHost}/login-identity-history/${liid}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    return resp.data;
  }

  async getAccounts(token: string): Promise<any> {
    try {
      const url = `${this.apiHost}/accounts`;
      const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      if (Array.isArray(resp.data) && resp.data.length > 0) {
        return resp.data.slice(-1)[0];
      }
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async getTransactions(token: string): Promise<any> {
    try {
      const url = `${this.apiHost}/transactions`;
      const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      if (Array.isArray(resp.data) && resp.data.length > 0) {
        return resp.data.slice(-1)[0];
      }
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async getStatements(token: string): Promise<any> {
    try {
      const url = `${this.apiHost}/statements`;
      const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      if (Array.isArray(resp.data) && resp.data.length > 0) {
        return resp.data.slice(-1)[0];
      }
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async getStatementLinkById(token: string, statementId: string): Promise<any> {
    const url = `${this.apiHost}/statement_links/${statementId}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    if (Array.isArray(resp.data) && resp.data.length > 0) {
      return resp.data.slice(-1)[0];
    }
    return resp.data;
  }

  async getTransactionsForAccountId(token: string, accountId: string, offset = 0, limit = 100): Promise<any> {
    const url = `${this.apiHost}/transactions/${accountId}`;
    const resp = await this.getData(
      url,
      {
        'content-type': contentType.json,
        Authorization: `Bearer ${token}`,
      },
      {
        offset,
        limit,
      },
    );
    if (Array.isArray(resp.data) && resp.data.length > 0) {
      return resp.data.slice(-1)[0];
    }
    return resp.data;
  }

  async getAccountNumberByAccountId(token: string, accountId: string, loginIdentityId: string): Promise<any> {
    const url = `${this.apiHost}/account_numbers/${loginIdentityId}/${accountId}`;
    try {
      const resp = await this.getData(url, {
        'content-type': contentType.json,
        Authorization: `Bearer ${token}`,
      });
      return resp.data;
    } catch (e) {
      const err = e as AxiosError;
      if (err.response?.status === 400) {
        return null;
      }
      throw err;
    }
  }

  async getIdentity(token: string): Promise<any> {
    const url = `${this.apiHost}/identity`;
    try {
      const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      if (Array.isArray(resp.data) && resp.data.length > 0) {
        return resp.data.slice(-1)[0];
      }
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async getBalanceHistory(token: string, loginIdentityId: string, accountId: string): Promise<any> {
    const url = `${this.apiHost}/balance_history/${loginIdentityId}/${accountId}`;
    try {
      const resp = await this.getData(url, {
        'content-type': contentType.json,
        Authorization: `Bearer ${token}`,
      });
      return resp.data;
    } catch (err) {
      if (err.response?.status === 400 || err.response?.status === 404) {
        return null;
      }
    }
  }

  async unlink(token: string): Promise<void> {
    const url = `${this.apiHost}/unlink`;
    await this.postData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` }, {});
    return;
  }

  async regenerateUserId(token: string, options?: GenerateLinkOptions): Promise<string> {
    const url = `${this.apiHost}/regenUserId`;
    const resp = await this.getData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      options,
    );
    return resp.data.accessToken;
  }

  async getLink(token: string, options?: GenerateLinkOptions): Promise<string> {
    const url = `${this.apiHost}/link`;
    const resp = await this.getData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      options,
    );
    return resp.data;
  }

  async getMandateLink(token: string, mandateLink: GenerateMandateLink): Promise<string> {
    const url = `${this.apiHost}/mandates/link`;
    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      mandateLink,
    );
    return resp.data;
  }

  async createPaymentInstruction(token: string, paymentInstruction: PaymentInstruction): Promise<any> {
    const url = `${this.apiHost}/payments/instruction`;
    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      paymentInstruction,
    );
    return resp.data;
  }

  async getPaymentInstruction(token: string, paymentInstructionId: string): Promise<any> {
    const url = `${this.apiHost}/payments/instruction/${paymentInstructionId}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    return resp.data;
  }

  async createMandate(token: string, mandate: CreateMandate): Promise<any> {
    const url = `${this.apiHost}/mandates`;
    const resp = await this.postData(
      url,
      { 'content-type': contentType.json, Authorization: `Bearer ${token}` },
      mandate,
    );
    return resp.data;
  }

  async getMandate(token: string, mandateId: string): Promise<any> {
    const url = `${this.apiHost}/mandates/${mandateId}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    return resp.data;
  }

  async getIncomeEstimation(token: string): Promise<any> {
    try {
      const url = `${this.apiHost}/income`;
      const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
      if (Array.isArray(resp.data) && resp.data.length > 0) {
        return resp.data.slice(-1)[0];
      }
      return resp.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async createPayment(token: string, payment: CreatePaymentRequest): Promise<any> {
    try {
      const url = `${this.apiHost}/payments`;
      const resp = await this.postData(
        url,
        {
          'content-type': contentType.json,
          Authorization: `Bearer ${token}`,
        },
        payment,
      );
      return resp.data;
    } catch (err) {
      return err.response;
    }
  }

  async getPayment(token: string, paymentId: string): Promise<PaymentResponse> {
    const url = `${this.apiHost}/payments/${paymentId}`;
    const resp = await this.getData(url, { 'content-type': contentType.json, Authorization: `Bearer ${token}` });
    return resp.data;
  }

  getXeroDemoUrl(): string {
    return `${this.apiHost}/xero/demo`;
  }

  // Helper functions to either get or post data. These are private functions so coverage should be ibgnored.
  /* istanbul ignore next */
  private async postData(url: string, headers = {}, data = {}): Promise<AxiosResponse> {
    const response = await axios.post(url, data, { headers });
    return response;
  }
  /* istanbul ignore next */
  private async getData(url: string, headers = {}, params?: any): Promise<AxiosResponse> {
    const response = await axios.get(url, { headers, params });
    return response;
  }
}
