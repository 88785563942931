import { ListItemText, Paper, Typography } from '@mui/material';

import { StyledListItem } from '../styledComponents';

interface Props {
  onClick: () => void;

  children: JSX.Element | string;
}

export default function ViewProductListItem({ onClick: handleClick, children }: Props): JSX.Element {
  return (
    <StyledListItem sx={{ marginBottom: '1rem' }} button component={Paper} onClick={handleClick}>
      <ListItemText>
        <Typography variant="subtitle1">{children}</Typography>
      </ListItemText>
    </StyledListItem>
  );
}
