import { useEffect, useState } from 'react';

import useSWR from 'swr';

import useAPIClient from '../../hooks/useClient';
import { isEndStatus } from '../../routes/Utilities';
import { getValue } from '../../services/storage';

export default function useLoginIdentityId(token?: string) {
  const userToken = token ?? String(getValue('userToken'));
  const client = useAPIClient();
  const [refreshInterval, setRefreshInterval] = useState(1 * 1000);

  const response = useSWR(
    ['/login-identity', userToken],
    (_: string, token: string) => client.getLoginIdentity(token),
    {
      refreshInterval,
      errorRetryCount: 0,
    },
  );

  useEffect(() => {
    if (isEndStatus(response.data?.login_identity.status ?? '')) {
      setRefreshInterval(0);
    }
  }, [response.data?.login_identity.status, refreshInterval]);

  return response;
}
