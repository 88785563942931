// takes URL to fvlink and adds the query param (demoApp=true)
export const makeFvLink = (url: string): string => {
  const urlObj = new URL(url);

  let searchParams = urlObj.search;
  if (searchParams.length > 1) {
    searchParams = searchParams.substring(1); // remove '?'
  }
  const searchParamsObj = new URLSearchParams(searchParams);
  searchParamsObj.set('demoApp', 'true');

  urlObj.search = '?' + searchParamsObj.toString();

  return urlObj.toString();
};
