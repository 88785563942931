import { useEffect, useMemo, useState } from 'react';

import { Payment, Receipt, MoreVert } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';

import ErrorSnack from '../components/ErrorSnack';
import JSONViewerDialogCommon from '../components/JSONViewerDialog';
import LinkLogo from '../components/LinkLogo';
import { RootContainer } from '../components/RootContainer';
import StatusIcon from '../components/StatusIcon';
import { StyledMenu, StyledMenuItem } from '../components/StyledMenu';
import useAPIClient from '../hooks/useClient';
import useValidToken from '../hooks/useValidToken';
import { PaymentResponse } from '../models/index';
import { isAuthorizationError } from '../services/api';
import { getValue } from '../services/storage';
import { isAPIError } from '../services/typeGuards';

const ListItemButtonStyle = {
  height: '56px',
  backgroundColor: '#FFFFFF',
  borderBottom: '1px solid #CFD1DC',
  '& img': {
    objectFit: 'contain',
  },
  '& svg': {
    verticalAlign: 'text-bottom',
  },
  width: '100%',
};

const StyledListItemText = styled(ListItemText)({
  '& span': {
    fontSize: '15px',
    color: '#201F24',
    fontWeight: '800',
  },
  '& p': {
    fontSize: '14px',
    color: '#A1A6C1',
  },
});

export enum CurrentDialog {
  NONE = 0,
  PAYMENT_JSON,
}

export default function PaymentDetails(): JSX.Element {
  const history = useHistory();
  const client = useAPIClient();

  const { paymentId } = useParams<{ paymentId: string }>();
  const token = getValue('userToken') ?? undefined;
  const { validToken } = useValidToken(token);

  const {
    data: payment,
    error: paymentError,
    mutate: mutatePayment,
  } = useSWR<PaymentResponse>(
    [`/payment/${paymentId}`, token, paymentId],
    (_: string, token: string, paymentId: string) => client.getPayment(token, paymentId),
  );
  const loading = !payment && !paymentError;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [dialog, setDialog] = useState<CurrentDialog>(CurrentDialog.NONE);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  const showErrorMessage = () => {
    setSnackOpen(true);
  };
  const handleViewClick = (dialog: CurrentDialog) => {
    setDialog(dialog);
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    handleMenuClose();
    setDialog(CurrentDialog.NONE);
  };

  const listItemSecondaryText = useMemo((): string => {
    if (isAPIError(payment)) return payment.message;
    if (payment == null || payment.payment_id == null) return 'Fetching payment...';
    return '';
  }, [payment]);

  const mutate = () => {
    mutatePayment();
  };

  useEffect(() => {
    if (validToken === undefined) {
      return;
    }
    if (!validToken) {
      // Token invlaid should route to login page
      history.push('/login');
    }
  }, [validToken, history]);

  useEffect(() => {
    const errors = [paymentError];

    if (errors.every((error) => error === undefined)) {
      return;
    }

    if (errors.some((error) => isAuthorizationError(error))) {
      history.push('/login');
      return;
    }

    showErrorMessage();
  }, [paymentError, history]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <RootContainer>
          <LinkLogo title="Payment Details" component={Payment} />
          <List>
            <ListItemButton
              component="a"
              key="payment"
              // cannot use styled api here because the types get overridden because of some styled & material ui types
              sx={ListItemButtonStyle}
            >
              <StyledListItemText primary="Payment Details" secondary={listItemSecondaryText} />
              <ListItemSecondaryAction>
                <StatusIcon status={payment?.status} />
                <IconButton disabled={loading} edge="end" aria-label="action" onClick={handleMenuClick} size="large">
                  <MoreVert />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Table aria-label="a table with details of the payment" sx={{ marginBottom: 4 }}>
              <TableBody>
                {Object.entries(payment ?? {}).map(([key, value]) => {
                  if (typeof value !== 'string' && typeof value !== 'number' && !Array.isArray(value)) {
                    return Object.entries(value).map(([subKey, subVal]) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {
                            // Title case
                            `${subKey === 'name' ? `${key[0].toUpperCase() + key.slice(1).toLowerCase()} ` : ''}${subKey
                              .split('_')
                              .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                              .join(' ')}`
                          }
                        </TableCell>
                        <TableCell>{typeof subVal === 'object' ? JSON.stringify(subVal, null, 1) : subVal}</TableCell>
                      </TableRow>
                    ));
                  }
                  if (key === 'amount' && typeof value === 'number') {
                    value = (value / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                  }
                  return (
                    <TableRow key={key}>
                      <TableCell component="th" scope="row">
                        {
                          // Title case
                          key
                            .split('_')
                            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                            .join(' ')
                        }
                      </TableCell>
                      <TableCell>{typeof value === 'object' ? JSON.stringify(value) : value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </List>
          <JSONViewerDialogCommon open={dialog === CurrentDialog.PAYMENT_JSON} json={payment} onClose={handleClose} />
          <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
            <StyledMenuItem onClick={() => handleViewClick(CurrentDialog.PAYMENT_JSON)}>
              <ListItemIcon>
                <Receipt fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="View Payment Details (raw JSON)" />
            </StyledMenuItem>
          </StyledMenu>
        </RootContainer>
      </Box>
      <ErrorSnack open={snackOpen} handleClose={handleSnackClose} retry={mutate} />
    </>
  );
}
