import useSWR from 'swr';

import useAPIClient from '../hooks/useClient';
import { getValue } from '../services/storage';

export default function useValidToken(token?: string) {
  const userToken = token ?? String(getValue('userToken'));
  const client = useAPIClient();

  const { data, error } = useSWR(
    ['/authorized', userToken],
    (_: string, userToken: string) => client.checkToken(userToken),
    {
      errorRetryCount: 0,
    },
  );

  return {
    validToken: data,
    error,
  };
}
