import { SetStateAction, useState } from 'react';

import {
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody as MUITableBody,
  styled,
  TablePagination,
} from '@mui/material';

import { BalanceHistoryResponse } from '../../models/index';
import { isFailure, balanceHistoryStatusDetailsLegit, isNotSupported } from '../../routes/Utilities';
import { StyledTable, StyledTableCell } from '../styledComponents';
import { EmptyTableBody, ErrorTableBody, FetchingTableBody, ProductNotSupported } from '../TableBody';

const MAX_ROW_PER_PAGE = 10; // The maximum number of rows in one signal table view

type Props = {
  balanceHistoryStatus: string;
  balanceHistoryStatusDetails: string;
  isLoading: boolean;
  balanceHistory?: BalanceHistoryResponse['balance_history'];
};

type TableBodyProps = Props & {
  canPaginate: boolean;
};

function TableBody({
  balanceHistoryStatus,
  balanceHistoryStatusDetails,
  isLoading,
  balanceHistory,
  canPaginate,
}: TableBodyProps) {
  const product = 'Balance History';
  const [page, setPage] = useState(0);

  const handleChangePage = (event: any, newPage: SetStateAction<number>) => {
    setPage(newPage);
  };

  const formatBalanceHistory = (value: number): string => {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  if (isFailure(balanceHistoryStatus)) {
    return <ErrorTableBody product={product} />;
  } else if (isNotSupported(balanceHistoryStatus)) {
    return <ProductNotSupported product={product} />;
  } else if (balanceHistoryStatusDetailsLegit(balanceHistoryStatusDetails) === false || isLoading) {
    return <FetchingTableBody product={product} />;
  } else if (!balanceHistory || balanceHistory.length === 0) {
    return <EmptyTableBody product={product} />;
  } else {
    return (
      <MUITableBody>
        {balanceHistory?.slice(page * MAX_ROW_PER_PAGE, page * MAX_ROW_PER_PAGE + MAX_ROW_PER_PAGE).map((balance) => (
          <StyledBalanceHistoryTableRow key={balance.date}>
            <TableCell>{new Date(balance.date).toLocaleDateString()}</TableCell>
            <TableCell>
              {formatBalanceHistory(balance.amount)} {balance.currency}
            </TableCell>
          </StyledBalanceHistoryTableRow>
        ))}
        {canPaginate && (
          <TableRow>
            <TablePagination
              page={page}
              onPageChange={handleChangePage}
              count={balanceHistory.length}
              rowsPerPage={MAX_ROW_PER_PAGE}
              rowsPerPageOptions={[]}
              labelDisplayedRows={() => `Page ${page + 1}`}
            />
          </TableRow>
        )}
      </MUITableBody>
    );
  }
}

const StyledBalanceHistoryTableRow = styled(TableRow)({
  height: '80px',
});

export default function BalanceHistoriesTable(props: Props): JSX.Element {
  // First check if transactions failed
  return (
    <TableContainer component={Paper}>
      <StyledTable aria-label="Balance History Details">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody
          {...props}
          canPaginate={(props.balanceHistory && props.balanceHistory.length >= 1 && !props.isLoading) ?? false}
        />
      </StyledTable>
    </TableContainer>
  );
}
